import Layout from "@/components/layout/Layout.vue";

const Login = (resolve) => require(["@/views/login/index"], resolve); //登入页

export default [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requireAuth: true,
      hidden: true,
      title: "登录",
    },
  },
  {
    path: "/home",
    name: "home",
    component: Layout,
    redirect: "noredirect",
    meta: {
      requireAuth: true,
      title: "首页",
    },
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/home/index"),
        meta: {
          title: "首页",
          icon: "dashboard",
        },
      },
    ],
  },
  {
    path: "/order",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "order-list",
        name: "OrderList",
        component: () => import("@/views/order/order-list"),
        meta: {
          title: "订单管理",
          icon: "dashboard",
        },
      },
      {
        path: "order-detail",
        name: "OrderDetail",
        component: () => import("@/views/order/order-detail"),
        meta: {
          title: "订单详情",
          icon: "dashboard",
        },
      },
    ],
  },
  {
    path: "/station",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "station-list",
        name: "StationList",
        component: () => import("@/views/station/station-list"),
        meta: {
          title: "站点管理",
          icon: "dashboard",
        },
      },
      {
        path: "station-add",
        name: "StationAdd",
        component: () => import("@/views/station/station-add"),
        meta: {
          title: "添加站点",
          icon: "dashboard",
        },
      },
      {
        path: "station-detail",
        name: "StationDetail",
        component: () => import("@/views/station/station-detail"),
        meta: {
          title: "站点详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/insure",
    component: Layout,
    redirect: "",
    children: [
      {
        path: "insure-record",
        name: "InsureRecord",
        component: () => import("@/views/insure/insure-record.vue"),
        meta: {
          title: "购保记录",
          icon: "dashboard",
        },
      },
    ],
  },
  {
    path: "/coupon",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "coupon-list",
        name: "CouponList",
        component: () => import("@/views/price/coupon-list"),
        meta: {
          title: "套餐管理",
          icon: "dashboard",
        },
      },
      {
        path: "add-coupon",
        name: "AddCoupon",
        component: () => import("@/views/price/addCoupon"),
        meta: {
          title: "添加套餐",
          icon: "dashboard",
        },
      },
      {
        path: "coupon-detail/:id",
        name: "CouponDetail",
        component: () => import("@/views/price/coupon-detail"),
        meta: {
          title: "套餐详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/price",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "price-list",
        name: "PriceList",
        component: () => import("@/views/price/list/index.vue"),
        meta: {
          title: "定价管理",
          icon: "dashboard",
        },
      },
      {
        path: "price-add",
        name: "PriceAdd",
        component: () => import("@/views/price/add/index.vue"),
        meta: {
          title: "新增定价",
        },
      },
      {
        path: "price-edit",
        name: "PriceEdit",
        component: () => import("@/views/price/edit/index.vue"),
        meta: {
          title: "修改定价",
        },
      },
      {
        path: "price-suggest",
        name: "price-suggest",
        component: () => import("@/views/price/suggest/index.vue"),
        meta: {
          title: "建议定价",
          hideTab: true,
        },
      },
      {
        path: "price-detail",
        name: "price-detail",
        component: () => import("@/views/price/detail/index.vue"),
        meta: {
          title: "定价详情",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/project",
    component: Layout,
    name: "project",
    redirect: "noredirect",
    children: [
      {
        path: "project-list",
        name: "ProjectList",
        component: () => import("@/views/project/project-list"),
        meta: {
          title: "项目管理",
          icon: "dashboard",
        },
      },
      {
        path: "project-add",
        name: "ProjectAdd",
        component: () => import("@/views/project/project-add"),
        meta: {
          title: "添加项目",
          icon: "dashboard",
        },
      },
      {
        path: "project-edit",
        name: "ProjectEdit",
        component: () => import("@/views/project/project-edit"),
        meta: {
          title: "编辑项目",
          icon: "dashboard",
        },
      },
      {
        path: "project-detail",
        name: "ProjectDetail",
        component: () => import("@/views/project/project-detail"),
        meta: {
          title: "项目详情",
          icon: "dashboard",
        },
      },
    ],
  },
  {
    path: "/recharge",
    component: Layout,
    redirect: "noredirect",
    name: "充值管理",
    children: [
      {
        path: "recharge-list",
        name: "RechargeList",
        component: () => import("@/views/recharge/recharge-list"),
        meta: {
          title: "充值管理",
          icon: "",
        },
      },
    ],
  },
  {
    path: "/customer",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "customer-list",
        name: "CustomerList",
        component: () => import("@/views/customer/customer-list"),
        meta: {
          title: "客户管理",
          icon: "dashboard",
        },
      },
      {
        path: "customer-detail",
        name: "CustomerDetail",
        component: () => import("@/views/customer/customer-detail"),
        meta: {
          title: "客户详情",
          icon: "dashboard",
        },
      },
      {
        path: "customer-add",
        name: "CustomerAdd",
        component: () => import("@/views/customer/customer-add"),
        meta: {
          title: "注册客户",
          icon: "dashboard",
        },
      },
      {
        path: "cooperate-list",
        name: "CooperateList",
        component: () => import("@/views/customer/cooperate-list"),
        meta: {
          title: "合作加盟",
          icon: "dashboard",
        },
      },
    ],
  },
  {
    path: "/userMGT",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "userMGT-list",
        name: "UserMGTList",
        component: () => import("@/views/userMGT/userMGT-list"),
        meta: {
          title: "用户管理",
          icon: "dashboard",
        },
      },
      {
        path: "userMGT-detail",
        name: "UserMGTDetail",
        component: () => import("@/views/userMGT/userMGT-detail"),
        meta: {
          title: "用户详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "feedback-list",
        name: "FeedbackList",
        component: () => import("@/views/userMGT/feedback-list"),
        meta: {
          title: "用户反馈",
          icon: "dashboard",
        },
      },
    ],
  },
  {
    path: "/device",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "device-list",
        name: "DeviceList",
        component: () => import("@/views/device/device-list"),
        meta: {
          title: "设备列表",
          icon: "dashboard",
        },
      },
      {
        path: "device-add",
        name: "DeviceAdd",
        component: () => import("@/views/device/device-add"),
        meta: {
          title: "新增设备",
          icon: "dashboard",
        },
      },
      {
        path: "device-edit",
        name: "DeviceEdit",
        component: () => import("@/views/device/device-edit"),
        meta: {
          title: "编辑设备",
          icon: "dashboard",
        },
      },
      {
        path: "device-info",
        name: "DeviceInfo",
        component: () => import("@/views/device/device-info"),
        meta: {
          title: "设备详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "device-type-list",
        name: "DeviceTypeList",
        component: () => import("@/views/device/device-type-list"),
        meta: {
          title: "设备类型列表",
          icon: "dashboard",
        },
      },
      {
        path: "device-type-edit",
        name: "DeviceTypeEdit",
        component: () => import("@/views/device/device-type-edit"),
        meta: {
          title: "设备类型编辑",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/fault",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "fault-list",
        name: "FaultList",
        component: () => import("@/views/fault/fault-list"),
        meta: {
          title: "故障列表",
          icon: "dashboard",
        },
      },
      {
        path: "fault-detail",
        name: "FaultDetail",
        component: () => import("@/views/fault/fault-detail"),
        meta: {
          title: "故障详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/revenueBills",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "revenueBills-list",
        name: "revenueBills-list",
        component: () => import("@/views/revenueBills/revenueBills-list"),
        meta: {
          title: "收益账单",
          icon: "dashboard",
        },
      },
      {
        path: "revenueBills-details",
        name: "revenueBills-details",
        component: () => import("@/views/revenueBills/revenueBills-details"),
        meta: {
          title: "账单明细",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "revenueBills-orderList",
        name: "revenueBills-orderList",
        component: () => import("@/views/revenueBills/revenueBills-orderList"),
        meta: {
          title: "订单列表",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },

  {
    path: "/businessAnalysis",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "businessAnalysis-list-business",
        name: "businessAnalysis-list-business",
        component: () => import("@/views/businessAnalysis/businessAnalysis-list-business"),
        meta: {
          title: "商户经营数据",
          icon: "dashboard",
        },
      },
      {
        path: "businessAnalysis-list-project",
        name: "businessAnalysis-list-project",
        component: () => import("@/views/businessAnalysis/businessAnalysis-list-project"),
        meta: {
          title: "项目经营数据",
          icon: "dashboard",
        },
      },
      {
        path: "businessAnalysis-list-station",
        name: "businessAnalysis-list-station",
        component: () => import("@/views/businessAnalysis/businessAnalysis-list-station"),
        meta: {
          title: "站点经营数据",
          icon: "dashboard",
        },
      },
      {
        path: "businessAnalysis-info",
        name: "businessAnalysis-info",
        component: () => import("@/views/businessAnalysis/businessAnalysis-info"),
        meta: {
          title: "经营分析详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/businessCenter",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "business-center",
        name: "BusinessCenter",
        component: () => import("@/views/businessCenter/business-center"),
        meta: {
          title: "商户中心",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "switch-business",
        name: "SwitchBusiness",
        component: () => import("@/views/businessCenter/switch-business"),
        meta: {
          title: "切换商户",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "withdrawal",
        name: "Withdrawal",
        component: () => import("@/views/businessCenter/withdrawal"),
        meta: {
          title: "提现",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "withdrawal-success",
        name: "WithdrawalSuccess",
        component: () => import("@/views/businessCenter/withdrawal-success"),
        meta: {
          title: "提现申请成功",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "bank-card-add",
        name: "BankCardAdd",
        component: () => import("@/views/businessCenter/bank-card-add"),
        meta: {
          title: "添加银行卡",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "bank-xy",
        name: "BankXy",
        component: () => import("@/views/businessCenter/bank-xy"),
        meta: {
          title: "用户服务协议及隐私政策",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/staffManage",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "staff-manage",
        name: "StaffManage",
        component: () => import("@/views/staffManage/staff-manage"),
        meta: {
          title: "员工管理",
          icon: "dashboard",
        },
      },
      {
        path: "staff-detail",
        name: "StaffDetail",
        component: () => import("@/views/staffManage/staff-detail"),
        meta: {
          title: "员工详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "staff-add",
        name: "StaffAdd",
        component: () => import("@/views/staffManage/staff-add"),
        meta: {
          title: "新建员工",
          icon: "dashboard",
        },
      },
      {
        path: "staff-exit",
        name: "StaffExit",
        component: () => import("@/views/staffManage/staff-exit"),
        meta: {
          title: "编辑员工",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/withdrawal",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "withdrawal-view",
        name: "WithdrawalView",
        component: () => import("@/views/withdrawal/withdrawal-view"),
        meta: {
          title: "提现管理",
          icon: "dashboard",
        },
      },
      {
        path: "withdrawal-detail",
        name: "WithdrawalDetail",
        component: () => import("@/views/withdrawal/withdrawal-detail"),
        meta: {
          title: "提现详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/chargeCard",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "chargeCard-list",
        name: "ChargeCardList",
        component: () => import("@/views/chargeCard/chargeCard-list"),
        meta: {
          title: "充电卡管理",
          icon: "dashboard",
        },
      },
      {
        path: "chargeCard-onLine",
        name: "ChargeCardOnLine",
        component: () => import("@/views/chargeCard/chargeCard-onLine"),
        meta: {
          title: "在线卡管理",
          icon: "dashboard",
        },
      },
      {
        path: "chargeCard-offLine",
        name: "ChargeCardOffLine",
        component: () => import("@/views/chargeCard/chargeCard-offLine"),
        meta: {
          title: "离线卡管理",
          icon: "dashboard",
        },
      },
    ],
  },
  {
    path: "/todolist",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "todolist-index",
        name: "TodolistIndex",
        component: () => import("@/views/todolist/todolist-index"),
        meta: {
          title: "待办事项",
          icon: "dashboard",
        },
      },
    ],
  },
  // 加盟商管理
  {
    path: "/franchisee",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: "franchisee-view",
        name: "franchisee-view",
        component: () => import("@/views/franchisee/franchisee-view"),
        meta: {
          title: "加盟商管理",
          icon: "dashboard",
        },
      },
      {
        path: "franchisee-detail",
        name: "franchisee-detail",
        component: () => import("@/views/franchisee/franchisee-detail"),
        meta: {
          title: "加盟商详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
      {
        path: "franchisee-change",
        name: "franchisee-change",
        component: () => import("@/views/franchisee/franchisee-change"),
        meta: {
          title: "变更加盟合同",
          icon: "dashboard",
        },
      },
      {
        path: "franchisee-contract",
        name: "franchisee-contract",
        component: () => import("@/views/franchisee/franchisee-contract"),
        meta: {
          title: "加盟商详情",
          icon: "dashboard",
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/admin-vue3",
    component: Layout,
    redirect: "noredirect",
    children: [
      {
        path: ":path",
        name: "admin-vue3",
        component: () => import("@/views/admin-vue3/index"),
        meta: {
          title: "admin-vue3",
          icon: "dashboard",
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/views/common/404.vue"),
    hidden: true,
  },
  {
    path: "*",
    redirect: "/home/index",
    hidden: true,
  },
];
