import { chargePlanTransform } from "@charge/common";

import request from "@/plugins/axios";

class CompanyChargePlanApi {
  addURL = "/api/company/platform/charge-plan/";
  addEffectKey = `post${this.addURL}`;
  add = async (data) => {
    const result = await request({
      url: this.addURL,
      data: data,
      method: "post",
    });
    return result;
  };

  editURL = "/api/company/platform/charge-plan/";
  editEffectKey = `post${this.editURL}`;
  edit = async (data) => {
    const result = await request({
      url: this.editURL,
      data: data,
      method: "put",
    });
    return result;
  };

  detailURL = "/api/company/platform/charge-plan/";
  detailEffectKey = `get${this.detailURL}`;
  detail = async (params) => {
    const result = await request({
      url: this.detailURL,
      params: params,
    });
    return chargePlanTransform.convertDetail(result);
  };

  deleteURL = "/api/company/platform/charge-plan/delete";
  deleteEffectKey = `put${this.deleteURL}`;
  delete = async (params) => {
    const result = await request({
      url: this.deleteURL,
      params: params,
      method: "put",
    });
    return result;
  };

  listURL = "/api/company/platform/charge-plan/list";
  listEffectKey = `post${this.listURL}`;
  list = async (data) => {
    const result = await request({
      url: this.listURL,
      data: data,
      method: "post",
    });
    return result;
  };

  defaultChargePlanURL = "/api/company/platform/charge-plan/default-charge-plan";
  defaultChargePlanEffectKey = `get${this.defaultChargePlanURL}`;
  defaultChargePlan = async (params) => {
    const result = await request({
      url: this.defaultChargePlanURL,
      params: params,
    });
    return chargePlanTransform.convertDefaultCharge(result);
  };

  listForStationURL = "/api/company/platform/charge-plan/list-for-station";
  listForStationEffectKey = `post${this.listForStationURL}`;
  listForStation = async (data) => {
    const result = await request({
      url: this.listForStationURL,
      data: data,
      method: "post",
    });
    return result;
  };

  chargePlanForStationURL = "/api/company/platform/charge-plan/charge-plan-for-station";
  chargePlanForStationEffectKey = `get${this.chargePlanForStationURL}`;
  chargePlanForStation = async (params) => {
    const result = await request({
      url: this.chargePlanForStationURL,
      params: params,
    });
    return result;
  };

  bindStationsURL = "/api/company/platform/charge-plan/bind-stations";
  bindStationsEffectKey = `post${this.bindStationsURL}`;
  bindStations = async (data) => {
    const result = await request({
      url: this.bindStationsURL,
      data: data,
      method: "post",
    });
    return result;
  };

  previewURL = "/api/company/platform/charge-plan/preview";
  previewEffectKey = `post${this.previewURL}`;
  preview = async (data) => {
    const result = await request({
      url: this.previewURL,
      data: data,
      method: "post",
    });
    return chargePlanTransform.convertPreview(result);
  };
}

export const companyChargePlanApi = new CompanyChargePlanApi();
