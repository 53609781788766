import { _get } from "@/axios/func";
import Config from "@/config";
import Utils from "@/utils";

import store from "..";
const initInfo = {
  id: "",
  mobile: "",
  avatarUrl: "",
  nickName: "",
  idCardNumber: "",
  idCardImagePath: "",
  idCardBackImagePath: "",
  realName: "",
};

const state = {
  user: {
    ...initInfo,
  },
};

const mutations = {
  setUserInfo: (state, userInfo) => {
    state.user = userInfo;
  },
};

const actions = {
  async getUserInfo({ commit }) {
    try {
      const arg = {
        url: Config.apiUrl.getUserInfo,
        params: {},
        showLoading: false,
      };
      const { result } = await _get(arg);

      if (result.code === 0) {
        const data = result.data;

        commit("setUserInfo", data);
        sessionStorage.setItem("userBalance", data.balance);
        sessionStorage.setItem("userlat", data.lat);
        sessionStorage.setItem("userlng", data.lng);
      }
      return result;
    } catch (err) {
      Utils.removeToken();
      window.location.hash = "#/login";
      throw err;
    }
  },

  // 前端 登出
  logout() {
    return new Promise((resolve) => {
      store.commit("menu/setMenu", []);
      Utils.removeToken();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
