<template>
  <div class="a-flex-rfsc a-ptb-12">
    <div v-show="label" class="s-search-label a-flex-rfec">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <span>{{ label }}</span>
    </div>
    <div class="a-flex-rfsc a-ml-24">
      <div
        v-for="(item, index) in options"
        :key="index"
        class="a-flex-ccc my-btn"
        :class="item.value == myValue ? 'my-btn-active' : ''"
        @click="handlerChange(item.value)"
      >
        <span class="a-fs-14 a-c-333">{{ item.label }}</span>
        <img
          v-if="item.value == myValue"
          src="../../../assets/icon/option-btn-check.png"
          style="width: 20px; height: 14px; position: absolute; bottom: -1px; right: -1px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      required: true,
    },
    /**
     * 是否必填
     */
    notnull: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      myValue: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        this.myValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    handlerChange(e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style scoped>
.my-btn {
  padding: 0 22px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #c4c8cc;
  position: relative;
  margin-right: 24px;
  cursor: pointer;
}

.my-btn-active {
  border: 1px solid #007aff;
}
</style>
