<template>
  <el-dialog title="选择定价" :visible.sync="dialogVisible">
    <div class="a-flex-rfsc">
      <le-input-icon-search
        v-model="pageParam.params.chargePlanName"
        placeholder="请输入定价名称"
      ></le-input-icon-search>
    </div>
    <div class="a-mt-24">
      <le-pagview ref="lePagview" :page-param="pageParam" @setData="setTableData">
        <el-table :data="priceList" height="425">
          <el-table-column prop="chargePlanName" label="定价名称"></el-table-column>
          <el-table-column prop="chargeModelLabel" label="定价类型"></el-table-column>
          <el-table-column prop="baseText" label="起步费用"></el-table-column>
          <el-table-column prop="overtimeText" label="加时费用"></el-table-column>
          <el-table-column label="免费时长">
            <template slot-scope="{ row }">
              <span>{{ row.freeChargeDuration }}分钟</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="{ row }">
              <el-button type="info" plain @click="handlerChoosePrice(row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </div>
  </el-dialog>
</template>

<script>
import { chargePlanTransform } from "@charge/common";

import { companyChargePlanApi } from "@/services/apis/company/charge-plan";

import util from "../../../utils/util.js";

export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
      default: false,
    },
    priceId: {
      required: false,
    },
    priceName: {
      required: false,
    },
    companyId: {
      default: "",
    },
  },
  data() {
    return {
      util: util,
      dialogVisible: false,
      pageParam: {
        url: companyChargePlanApi.listForStationURL,
        method: "post",
        params: {
          chargePlanName: "",
        },
      },
      priceList: [],
    };
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
    },
    dialogVisible: {
      handler(val, oldval) {
        if (val != oldval) {
          this.$emit("update:visible", val);
        }
        if (val) {
          this.$nextTick(() => {
            this.pageParam.params.currentCompanyId && this.$refs.lePagview.getTableData();
          });
        }
      },
    },
    companyId(val) {
      this.pageParam.params.currentCompanyId = val;
    },
  },

  methods: {
    handlerChoosePrice(item) {
      this.dialogVisible = false;
      this.$emit("update:priceId", item.id);
      this.$emit("update:priceName", item.chargePlanName);
    },

    setTableData(data) {
      this.priceList = chargePlanTransform.convertList(data);
    },
  },
};
</script>
