<template>
  <div class="tags-view-container">
    <scroll-pane ref="scrollPane" class="tags-view-wrapper">
      <div class="a-flex-rfsfe">
        <router-link
          v-for="tag in visitedViews"
          ref="tag"
          :key="tag.path"
          :class="isActive(tag) ? 'active' : ''"
          :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
          class="tags-view-item"
          style="margin-left: 4px"
          @click.middle.native="closeSelectedTag(tag)"
          @contextmenu.prevent.native="openMenu(tag, $event)"
        >
          <span>{{ tag.title }}</span>
          <span
            v-if="isActive(tag)"
            class="el-icon-refresh-right a-ml-08 _refresh-icon"
            :class="{ '_refresh-icon_animation': showAnimation }"
            style="width: 9px; height: 9px; padding-bottom: 3px"
            @click.prevent.stop="refreshSelectedTag(tag)"
          />
          <span
            class="el-icon-close a-ml-08"
            style="width: 9px; height: 9px; padding-bottom: 3px"
            @click.prevent.stop="closeSelectedTag(tag)"
          />
        </router-link>
      </div>
    </scroll-pane>
  </div>
</template>

<script>
import wujieVue from "wujie-vue2";

import ScrollPane from "@/components/ScrollPane";
export default {
  components: {
    ScrollPane,
  },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      whiteList: [],
      showAnimation: false,
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
  },
  watch: {
    $route() {
      this.addViewTags();
      this.moveToCurrentTag();
    },
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
  },
  mounted() {
    this.addViewTags();
  },
  methods: {
    isActive(route) {
      return route.path === this.$route.path;
    },
    addViewTags() {
      const { name } = this.$route;
      if (name) {
        this.$store.dispatch("addView", this.$route);
      }
      if (Object.prototype.hasOwnProperty.call(this.$route.meta, "hash")) {
        delete this.$route.meta.hash;
      }
      return false;
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag;
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag);
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch("updateVisitedView", this.$route);
            }
            break;
          }
        }
      });
    },

    refreshSelectedTag() {
      if (!this.showAnimation) {
        this.$emit("refresh");
        wujieVue.bus.$emit("refresh");
        this.showAnimation = true;
        setTimeout(() => {
          this.showAnimation = false;
        }, 1200);
      }
    },

    closeSelectedTag(view) {
      if (this.isActive(view)) {
        view.meta.hash = "del";
      }
      this.$store.dispatch("delCachedViewThis", this);
      this.$store.dispatch("delView", view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          const latestView = visitedViews.slice(-1)[0];
          if (latestView) {
            this.$router.push(latestView);
          } else {
            this.$router.push("/");
          }
        }
      });
    },

    closeOthersTags() {
      this.$router.push(this.selectedTag);
      this.$store.dispatch("delOthersViews", this.selectedTag).then(() => {
        this.moveToCurrentTag();
      });
    },

    closeAllTags() {
      this.$store.dispatch("delAllViews");
      this.$router.push("/");
    },

    openMenu(tag, e) {
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      this.top = e.clientY;

      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu() {
      this.visible = false;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.tags-view-container {
  height: 40px;
  width: 100%;
  background: #ebf0f5;

  .tags-view-wrapper {
    margin-top: 8px;

    .tags-view-item {
      padding: 0 16px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border: 1px solid #dadde0;
      border-bottom: 0px;
      height: 32px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: #f4f6f9;
      font-size: 12px;
      color: #606366;

      &.active {
        // background-color: rgb(64, 158, 255);
        color: #007aff;

        // border-color: #42b983;
        &::before {
          content: "";
          background: #fff;
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: relative;
          margin-right: 6px;
          background-color: #007aff;
          margin-bottom: 2px;
        }
      }
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 100;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }
}
@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

._refresh-icon {
  &_animation {
    animation: rotateAnimation 1.2s;
  }
}

.el-scrollbar__wrap {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: flex-end !important;
  height: 40px !important;
  padding: 0px;
}

.el-scrollbar__view {
  display: inline-block !important;
}
</style>
