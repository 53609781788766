<template>
  <div class="a-w-100 a-h-100">
    <div class="main-container a-w-100 a-h-100">
      <navbar />
      <div
        class="a-flex-rfsfs mainBoxRight"
        :class="[sidebarOpened ? 'openSidebar' : 'hideSidebar']"
      >
        <sidebar></sidebar>
        <div class="containBox a-flex-cfsfs a-flex-1">
          <tags-view @refresh="onRefreshPage" />
          <app-main :show="show" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import { AppMain, Navbar, Sidebar, TagsView } from "./components";

export default {
  name: "Layout",

  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    ...mapState({
      routeHis: (state) => state.menu.routeHis,
      sidebarOpened: (state) => state.hamburger.sidebarOpened,
    }),
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
  },

  mounted() {
    this.show = true;
  },

  methods: {
    ...mapMutations("tagsView", ["DEL_CACHED_VIEW"]),

    handleClickOutside() {
      this.$store.dispatch("CloseSideBar", {
        withoutAnimation: false,
      });
    },

    onRefreshPage() {
      this.$store.dispatch("delCachedView", this.$route).then(() => {
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
          this.$store.dispatch("addView", this.$route);
        });
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.containBox {
  width: calc(100% - 270px);
}

.mainBoxRight {
  position: relative;
  top: 64px;
  height: calc(100% - 64px);
}

.main-container {
  position: relative;
}

.el-scrollbar /deep/ .el-scrollbar__bar {
  display: none !important;
}

.el-scrollbar /deep/ .my-menu {
  overflow-x: hidden !important;
}

.openSidebar /deep/ .el-scrollbar__view > .el-menu {
  width: 158px;
}
.hideSidebar /deep/ .el-scrollbar__view > .el-menu.el-menu--collapse {
  width: 42px;
}

.openSidebar /deep/ .el-submenu__icon-arrow {
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  margin-top: 0;
}
.hideSidebar /deep/ .el-submenu__icon-arrow {
  display: none;
}
.hideSidebar /deep/ .el-badge {
  display: none;
}
</style>
