<template>
  <div v-if="!item.hidden" class="menu-wrapper">
    <el-submenu v-if="item.children.length > 1" :index="resolvePath(item.children[0].path)">
      <template slot="title">
        <item
          :title="item.name"
          :icon="$route.path.split('/')[1] != item.path.split('/')[1] ? item.icon : item.iconActive"
          :color-class="
            $route.path.split('/')[1] != item.children[0].path.split('/')[1]
              ? 'a-c-master'
              : 'a-c-blue'
          "
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
      <!-- <app-link
                    v-else
                    :to="resolvePath(child.path)"
                    :key="child.name"
                    :underline="false"
                >
                    <el-menu-item :index="resolvePath(child.path)">
                        <item v :title="child.name" />
                    </el-menu-item>
                </app-link> -->
    </el-submenu>

    <el-menu-item
      v-else
      :index="item.children.length ? item.children[0].path : item.path"
      class="menuitemnobody"
    >
      <item
        :title="item.name"
        :icon="$route.path.split('/')[1] != item.path.split('/')[1] ? item.icon : item.iconActive"
        :color-class="
          $route.path.split('/')[1] != item.path.split('/')[1] ? 'a-c-master' : 'a-c-blue'
        "
      />

      <el-badge
        v-if="item.name == '待办事项' && todonum.totalNum"
        :value="todonum.totalNum"
        :max="99"
        class="itembadge"
      ></el-badge>
    </el-menu-item>
  </div>
</template>

<script>
import path from "path";
import { mapState } from "vuex";

import Item from "./Item";

export default {
  name: "SidebarItem",
  components: {
    Item,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  computed: {
    ...mapState({
      todonum: (state) => state.todonum.todonum,
    }),
  },
  methods: {
    hasOneShowingChild(children, parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          this.onlyOneChild = item;
          return true;
        }
      });

      if (showingChildren.length === 1) {
        return true;
      }

      if (showingChildren.length === 0) {
        this.onlyOneChild = {
          ...parent,
          path: "",
          noShowingChildren: true,
        };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    isExternalLink(routePath) {
      return this.$Utils.isExternal(routePath);
    },
  },
};
</script>

<style scoped lang="scss">
.el-submenu .el-menu-item {
  min-width: 158px !important;
}

.itembadge /deep/ .el-badge__content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #ff3b30;
  margin-left: 8px;
  top: 0;
}
.menuitemnobody {
  position: relative;
  /deep/ .el-badge {
    position: absolute;
    right: 13px;
    top: calc(50% - 9px);
  }
}
/deep/ .el-submenu.is-active .el-submenu__title span {
  color: rgb(0, 122, 255);
}
</style>
