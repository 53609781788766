// import 'echarts/lib/chart/radar'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/chart/bar'
// import 'echarts/lib/chart/pie'
// import 'echarts/lib/chart/effectScatter';
// import 'echarts/lib/chart/lines';
// 导入工具部分
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
// register component to use
import "echarts/lib/component/legendScroll";
// register component to use
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markArea";
import "echarts/lib/component/polar";
import "echarts/lib/component/geo";

import Vue from "vue";
import ECharts from "vue-echarts";

// register component to use
Vue.component("Chart", ECharts);
