<template>
  <div>
    <div v-if="show" id="search-form">
      <div class="a-flex-rfsfs a-flex-wrap">
        <template>
          <slot></slot>
        </template>

        <div class="a-ptb-12 a-flex-rfsc a-flex-1 searchToo a-ml-30" style="">
          <template v-if="canPackUp && multiRow">
            <template v-if="show">
              <div class="a-flex-rcc a-c-master a-fs-14 font-point" @click="show = false">
                <span>收起</span>
                <i class="el-icon-arrow-up a-ml-08"></i>
              </div>
            </template>
            <template v-else>
              <div class="a-flex-rcc a-c-master a-fs-14 font-point" @click="show = true">
                <span>展开</span>
                <i class="el-icon-arrow-down a-ml-08"></i>
              </div>
            </template>
          </template>
          <el-button size="small" class="a-ml-15" @click="handlerReset">重置</el-button>
          <el-button size="small" type="primary" class="a-ml-15" @click="handlerSearch">
            查询
          </el-button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="a-flex-rsbfe">
        <div class="a-flex-rfsfs a-flex-wrap a-flex-1" :style="getStype()">
          <template>
            <slot></slot>
          </template>
        </div>
        <div class="a-ptb-12 a-flex-rfsc searchToo a-ml-30" style="">
          <template v-if="canPackUp">
            <template v-if="show">
              <div class="a-flex-rcc a-c-master a-fs-14 font-point" @click="show = false">
                <span>收起</span>
                <i class="el-icon-arrow-up a-ml-08"></i>
              </div>
            </template>
            <template v-else>
              <div class="a-flex-rcc a-c-master a-fs-14 font-point" @click="show = true">
                <span>展开</span>
                <i class="el-icon-arrow-down a-ml-08"></i>
              </div>
            </template>
          </template>
          <el-button size="small" class="a-ml-15" @click="handlerReset">重置</el-button>
          <el-button size="small" type="primary" class="a-ml-15" @click="handlerSearch">
            查询
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canPackUp: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: true,
      multiRow: false,
    };
  },
  mounted() {
    const form = document.querySelector("#search-form");
    const height = form.getBoundingClientRect().height;
    if (height > 100) {
      this.multiRow = true;
    }

    const that = this;
    document.onkeydown = function (event) {
      const e = event || window.event;
      if (e && e.keyCode == 13) {
        //回车键的键值为13
        that.handlerSearch();
      }
    };
  },
  methods: {
    handlerReset(e) {
      this.$emit("reset", e);
    },
    handlerSearch(e) {
      this.$emit("search", e);
    },
    getStype() {
      if (!this.show) {
        return {
          height: "56px",
          overflow: "hidden",
        };
      } else {
        return {};
      }
    },
  },
};
</script>
