<template>
  <el-input
    :value="innerValue"
    v-bind="innerAttrs"
    @input="onInput"
    @blur="onBlur"
    v-on="$listeners"
  >
    <template v-for="(_, key) in $slots" #[key]>
      <slot :name="key" />
    </template>
  </el-input>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    // number numberWithZero decimal decimalWithZero
    inputType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      innerValue: "",
    };
  },

  computed: {
    innerAttrs() {
      return {
        // type: "number",
        ...this.$attrs,
      };
    },
  },

  watch: {
    value: {
      handler(val) {
        this.innerValue = val;
      },
      immediate: true,
    },
  },

  methods: {
    onInput(val) {
      let result = val;
      const transformMap = {
        number: () => this.transformNumber(val),
        numberWithZero: () => this.transformNumberWithZero(val),
        decimal: () => this.transformDecimal(val),
        decimalWithZero: () => this.transformDecimal(val),
      };
      if (transformMap[this.inputType]) {
        result = transformMap[this.inputType]();
      }
      this.$nextTick(() => {
        this.updateValue(result);
      });
    },

    transformNumber(val) {
      let result = (val + "").replace(/\D/g, "");
      result = result.replace(/^0.*/, "");
      return result;
    },

    transformNumberWithZero(val) {
      let result = (val + "").replace(/\D/g, "");
      result = result.replace(/^0([1-9]\d*)?/, (_, $1) => {
        if ($1) {
          return $1;
        }
        return 0;
      });
      return result;
    },

    transformDecimal(val) {
      let result = (val + "").replace(/[^0-9.]/g, "");
      result = result.replace(/^(?:0+)?(\d+\.?\d?[1-9]?).*/, (_, $1) => {
        return $1;
      });
      return result;
    },

    onBlur() {
      if (this.inputType === "decimal" && this.innerValue !== "") {
        this.updateValue(parseFloat(this.innerValue) || 0.01);
      }
      if (this.inputType === "decimalWithZero" && this.innerValue !== "") {
        this.updateValue(parseFloat(this.innerValue));
      }
    },

    updateValue(val) {
      // let result = val;
      // if (this.innerAttrs.maxlength && val.length > this.innerAttrs.maxlength) {
      //   result = val - 1;
      // }
      this.innerValue = val;
      this.$emit("input", val);
    },
  },
};
</script>
