<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
/* 全局滚动条样式 */
::-webkit-scrollbar-track-piece {
  width: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background: #cccccc;
  border-radius: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.9);
  cursor: pointer;
}

/* 弹窗出现，页面内容向右bug */
.el-popup-parent--hidden {
  padding-right: 0 !important;
}
</style>
