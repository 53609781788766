<template>
  <div class="a-flex-rfsc">
    <div v-show="label" class="s-search-label" style="white-space: nowrap">
      <div>{{ label }}</div>
    </div>
    <el-input
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
      :maxlength="maxlength"
      @input="handlerChange"
    >
      <template slot="prepend">¥</template>
    </el-input>
  </div>
</template>

<script>
import util from "@/utils/util";
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      required: true,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
    maxlength: {
      default: 6,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handlerChange(e) {
      e = util.checkMoney(e);
      this.$emit("input", e);
    },
  },
};
</script>
