import hichar from "@/config/api/hichar";

const BASE_MAP = {
  // 开发
  DEV: "",
  // DEV: 'https://release-api-charge.ledear.cn',
  // DEV: "https://api-charging.ledear.cn",
  // 测试
  TEST: "https://test-api-charge.ledear.cn",
  // 预发
  RELEASE: "https://release-api-charge.ledear.cn",
  // 生产
  PROD: "https://api-charging.ledear.cn",
};

export const ADMIN_H5_ADDRESS =
  process.env.NODE_ENV === "production" ? "./admin-h5/index.html" : "http://192.168.3.61:3000/";

export const ADMIN_VUE3_ADDRESS =
  process.env.NODE_ENV === "production" ? "./admin-vue3/index.html#/" : "//localhost:5173/";

const config = {
  projectName: "",
  projectTitle: "",
  baseUrl: "",
  brand: "雅迪充电",
  appId: "hichar.company.web.yd",
  header: [
    {
      key: "appId",
      value: "hichar.company.web.yd",
    },
  ],
  tokenKey: "JHDesignToken",
  timeout: 1000 * 60 * 60, // 1个小时
  defaultTimeout: 1000 * 60 * 5, // 5分钟

  // axiosBaseUrl: process.env.NODE_ENV === 'production' ? 'https://api.zc.meishutech.com' : 'https://api.zc.meishutech.com/', //https://zjp.meishutech.com/charging-api-new-wsx
  //axiosBaseUrl: process.env.NODE_ENV === 'production' ? 'https://api.v2.yd.hichar.cn' : 'http://zjp.meishutech.com/charging-api-new-wsx/', //
  //axiosBaseUrl: process.env.NODE_ENV === 'production' ? 'http://zjp.meishutech.com/charging-api-new-wsx/' : 'http://api.v2.yd.hichar.cn/'
  axiosBaseUrl: BASE_MAP[process.env.VUE_APP_BASE_URL || "PROD"],

  apiUrl: {
    ...hichar,
  },
};

// axios 默认配置
export const AXIOS_DEFAULT_CONFIG = {
  baseURL: config.axiosBaseUrl,
  timeout: config.defaultTimeout,
  headers: {
    appId: config.appId,
  },
};

export default config;
