<template>
  <div class="a-flex-rfsc a-ptb-12">
    <div v-show="label" class="s-search-label a-flex-rfec">
      <span v-if="notnull" class="a-c-red a-fs-14" style="margin-right: 4px">*</span>
      <span>{{ label }}</span>
    </div>
    <div class="a-flex-cfsfs a-ml-20">
      <el-cascader
        ref="mycascader"
        v-model="selectedOptions"
        :class="large ? 's-large-input' : 's-nomarl-input'"
        placeholder="请选择省市区"
        :options="options"
        clearable
        @change="handleChange"
      ></el-cascader>
    </div>
  </div>
</template>

<script>
/**
 * 省市区选择
 */
import { regionData } from "element-china-area-data";
export default {
  props: {
    province: {
      required: false,
      type: String,
    },
    city: {
      required: false,
      type: String,
    },
    area: {
      required: false,
      type: String,
    },
    placeholder: {
      type: String,
      default: "请输入详细地址",
    },
    label: {
      type: String,
    },
    /**
     * 是否必填
     */
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      options: regionData,
      selectedOptions: ["", "", ""],
      provinceChange: false,
      cityChange: false,
      areaChange: false,
      scrollview: null,
    };
  },
  computed: {
    codeChange() {
      return {
        provinceChange: this.provinceChange,
        cityChange: this.cityChange,
        areaChange: this.areaChange,
      };
    },
  },
  watch: {
    province: {
      handler(val, oldval) {
        if (val != oldval || !oldval) {
          this.provinceChange = true;
        }
      },
    },
    city: {
      handler(val, oldval) {
        if (val != oldval || !oldval) {
          this.cityChange = true;
        }
      },
    },
    area: {
      handler(val, oldval) {
        if (val != oldval || !oldval) {
          this.areaChange = true;
        }
      },
    },
    codeChange: {
      handler() {
        if (this.provinceChange && this.cityChange && this.areaChange) {
          this.$set(this, "selectedOptions", [this.province, this.city, this.area]);
          this.provinceChange = false;
          this.cityChange = false;
          this.areaChange = false;
        }
      },
    },
  },
  mounted() {
    // 获取指定元素
    this.scrollview = document.getElementsByClassName("el-main")
      ? document.getElementsByClassName("el-main")[0]
      : null;
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    if (this.scrollview && this.$refs.mycascader) {
      this.scrollview.addEventListener(
        "scroll",
        () => {
          this.$refs.mycascader.dropDownVisible = false;
        },
        true
      );
    }
  },
  beforeDestroy() {
    if (this.scrollview) this.scrollview.removeEventListener("scroll", () => {}, true);
  },
  methods: {
    handleChange(value) {
      this.$emit("update:province", value[0]);
      this.$emit("update:city", value[1]);
      this.$emit("update:area", value[2]);
    },
  },
};
</script>
