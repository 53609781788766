<template>
  <div
    ref="echartsRef"
    style="width: auto; height: calc(100vh - 630px); margin-top: 34px; min-height: 280px"
  ></div>
</template>

<script>
export default {
  props: {
    echartsObj: {
      type: Object,
    },
  },
  data() {
    return {
      echarts: null,
      echartOptions: {},
      color: ["#6699FF", "#FFA900", "#3FE33F", "#F63535"],
      inactiveColor: [
        "rgba(102,153,255,0.3)",
        "rgba(255,169,0,0.3)",
        "rgba(63,227,63,0.3)",
        "rgba(246,53,53,0.3)",
      ],
    };
  },
  watch: {
    echartsObj: {
      handler(newval) {
        if (newval) {
          setTimeout(() => {
            this.drawChart(
              this.echartsObj,
              this.echartsObj.xAxisData,
              this.echartsObj.seriesData0,
              this.echartsObj.seriesData1,
              this.echartsObj.seriesName0,
              this.echartsObj.seriesName1,
              this.echartsObj.echartsType
            );
          }, 500);
        }
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    drawChart(echartsObj, xAxis = []) {
      const chartDom = this.$refs["echartsRef"];
      const option = {
        legend: {
          itemWidth: 8,
          itemHeight: 8,
          data: Object.keys(echartsObj)
            .filter((key) => {
              return key.indexOf("seriesName") != -1;
            })
            .map((key) => {
              return {
                name: echartsObj[key],
                icon: "roundRect",
              };
            }),
          right: "2%",
          itemGap: 40,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255, 255, 0.96)",
          extraCssText:
            "width:146px;height:auto;box-shadow: 0px 4px 16px 0px rgba(139, 147, 167, 0.25);",
          textStyle: {
            color: "#303133",
          },
          padding: 10,
          formatter: function (params) {
            // console.log('formatter',params)

            let art = `<div style="padding-left:10px">
                            <div class="a-fs-12 a-c-normal">${params[0].axisValue}</div>`;

            params.map((item, index) => {
              art += `<div class="a-fs-16 a-fw-700 a-ptb-5">
								<span class="a-fs-14 a-c-333 a-fw-400">${item.seriesName}：${item.value} ${
                echartsObj["seriesUnit" + index] ? echartsObj["seriesUnit" + index] : ""
              }</span>
							</div>`;
            });
            art += `</div>`;
            return art;
          },
        },
        color: this.color,
        grid: {
          left: "1%",
          right: "2%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xAxis,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#ddd",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#ddd",
              length: 4,
            },
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: "#606366",
            },
            // fontStyle: 'italic',
            formatter: function (value) {
              return value.split("-")[1] + "/" + value.split("-")[2];
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
              color: "#eee",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#606366",
            },
          },
          name: this.echartsObj.yName ? this.echartsObj.yName : "",
          nameTextStyle: {
            align: "center",
            verticalAlign: "bottom",
            lineHeight: 36,
          },
        },
        series: Object.keys(this.echartsObj)
          .filter((item) => {
            return item.indexOf("seriesData") != -1;
          })
          .map((item, index) => {
            return {
              symbol: "none",
              name: this.echartsObj["seriesName" + index],
              data: this.echartsObj["seriesData" + index],
              type: "line",
              smooth: true,
              lineStyle: {
                width: 3,
                color: this.color[index],
                shadowColor: this.inactiveColor[index],
                shadowBlur: 7,
                shadowOffsetY: 6,
              },
            };
          }),
      };
      if (this.echarts) {
        this.echarts.dispose();
      }
      if (chartDom) {
        this.echarts = this.$echarts.init(chartDom);

        this.echarts.setOption(option, true);
      }

      window.addEventListener("resize", () => {
        if (this.echarts) {
          this.echarts.resize();
        }
      });
    },
  },
};
</script>

<style></style>
