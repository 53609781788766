import Vue from "vue";
import WujieVue from "wujie-vue2";

import { ADMIN_VUE3_ADDRESS } from "@/config";
import router from "@/router";
import store from "@/store";

const { setupApp, bus } = WujieVue;

// 在 xxx-sub 路由下子应用将激活路由同步给主应用
bus.$on("sub-route-change", (name, path) => {
  const mainName = name;
  const mainPath = `/${name}${path}`;
  const currentName = router.currentRoute.name;
  const currentPath = router.currentRoute.path;
  if (mainName === currentName && mainPath !== currentPath) {
    router.push({ path: mainPath });
  }
});

bus.$on("tokenError", async () => {
  await store.dispatch("user/logout");
  router.replace({
    path: "/login",
  });
});

// name名称需要统一，包括使用wujievue组件的地方
setupApp({
  name: "admin-vue3",
  url: ADMIN_VUE3_ADDRESS,
  exec: true,
  alive: true,
});

Vue.use(WujieVue);
