<template>
  <div class="a-flex-rfsc a-ptb-12" v-bind="$attrs">
    <div v-show="label" class="s-search-label a-flex-rfec">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <span>{{ label }}</span>
    </div>
    <el-select
      ref="myselect"
      class="a-ml-20 a-flex-1"
      :class="large ? 's-large-input' : 's-nomarl-input'"
      :value="value"
      :filterable="filterable"
      :clearable="clearble"
      :placeholder="placeholder"
      @change="handlerChange"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    /**
     * 是否必填
     */
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterable: {
      default: true,
    },
    clearble: {
      default: true,
    },
  },
  data() {
    return {
      scrollview: null,
    };
  },
  mounted() {
    // 获取指定元素
    this.scrollview = document.getElementsByClassName("el-main")
      ? document.getElementsByClassName("el-main")[0]
      : null;
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    if (this.scrollview && this.$refs.myselect) {
      this.scrollview.addEventListener(
        "scroll",
        () => {
          this.$refs.myselect.blur();
        },
        true
      );
    }
  },
  beforeDestroy() {
    if (this.scrollview) this.scrollview.removeEventListener("scroll", () => {}, true);
  },
  methods: {
    handlerChange(e) {
      this.$emit("input", e);
    },
  },
};
</script>
