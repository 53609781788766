import { render, staticRenderFns } from "./le-switch-btn.vue?vue&type=template&id=205715be&scoped=true"
import script from "./le-switch-btn.vue?vue&type=script&lang=js"
export * from "./le-switch-btn.vue?vue&type=script&lang=js"
import style0 from "./le-switch-btn.vue?vue&type=style&index=0&id=205715be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205715be",
  null
  
)

export default component.exports