<template>
  <div class="a-mt-16 a-flex-cfsfs a-flex-1 aaaa" :class="{ 'cg-min-h-0': isOneScreen }">
    <slot name="headerLabel" :val="totalVal"></slot>
    <div class="a-flex-1 a-w-100" :class="{ 'cg-min-h-0': isOneScreen }">
      <slot></slot>
    </div>

    <div class="a-flex-rsbc a-w-100 a-mt-24">
      <slot name="footerLabel" :val="totalVal"></slot>
      <div class="a-flex-rfec a-flex-1">
        <el-pagination
          :current-page="pageNum"
          :page-size="10"
          :page-sizes="[10, 20, 50, 100]"
          layout="sizes, prev, pager, next"
          :total="total"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { mapState } from "vuex";

export default {
  props: {
    pageParam: {
      type: Object,
      required: true,
      validator: (param) => {
        if (!param.url && !param.method && !param.getDataFn) {
          return false;
        }
        if (!param.params) {
          return false;
        }
        return true;
      },
    },
    freshCacheCtrl: {
      type: Number,
      default: -1,
    },
    isOneScreen: Boolean,
  },
  data() {
    this.loading = null;
    return {
      total: 30,
      totalVal: -1, // 表格数据左下角
      pageNum: 1,
      pageSize: 10,
      knowTotal: false,
      freshCtrl: -1,
    };
  },
  computed: {
    ...mapState("loading", ["effects"]),

    fetching() {
      return this.effects[this.pageParam.effectKey];
    },
  },
  watch: {
    pageParam: {
      handler(val) {
        if (val && ((val.url && val.method) || val.getDataFn) && val.params) {
          if (val.freshCtrl) {
            if (this.freshCtrl != val.freshCtrl) {
              this.pageNum = 1;
              this.freshCtrl = val.freshCtrl;
              this.getTableData();
            }
          } else {
            this.getTableData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    freshCacheCtrl() {
      this.getTableData();
    },
    fetching: {
      handler(val) {
        if (val) {
          this.loading = Loading.service({
            lock: true,
            text: "加载中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.2)",
          });
        } else {
          this.loading?.close();
        }
      },
    },
  },

  methods: {
    getTableData() {
      if (!this.pageParam.getDataFn) {
        this.oldGetTableData();
      } else {
        this.newGetTalbeData();
      }
    },

    oldGetTableData() {
      let func = this.$Axios._post;
      if (this.pageParam.method.toLowerCase() == "get") {
        func = this.$Axios._get;
      }
      func({
        ...this.pageParam,
        params: {
          ...this.pageParam.params,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.handleGetTableDataSuccess(res.result.data);
      });
    },

    async newGetTalbeData() {
      const res = await this.pageParam.getDataFn({
        ...this.pageParam.params,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      this.handleGetTableDataSuccess(res);
    },

    handleGetTableDataSuccess(data) {
      const dataWithNumber = data.list.map((v, i) => ({
        ...v,
        __number__: ((data.pageNumber || data.pageNum) - 1) * data.pageSize + i + 1,
      }));
      this.$emit("setData", dataWithNumber, data.excelUrl, data, data.pageNumber || data.pageNum);
      this.$emit("update:total", data.total);
      /**
       * 有分页总数时
       */
      if (data.total != -1) {
        this.total = data.total;
        this.totalVal = data.total;
      } else {
        /**
         * 无分页总数时
         */
        if (data.length < this.pageSize) {
          this.total = this.pageNum * this.pageSize - (this.pageSize - data.length);
          this.knowTotal = true;
        } else if (!this.knowTotal) {
          const total = (this.pageNum + 1) * this.pageSize;
          if (total > this.total) {
            this.total = total;
          }
        }
      }
    },

    // 行数切换
    handleSizeChange(value) {
      this.pageSize = value;
      this.pageNum = 1;
      this.getTableData();
    },
    // 页码切换
    handleCurrentChange(value) {
      this.pageNum = value;
      this.getTableData();
    },
  },
};
</script>

<style>
.aaaa:after {
  content: "";
  display: block;
  clear: both;
}
</style>
