import { _post } from "@/axios/func";
import Config from "@/config";

const todonumObj = {
  todolist: [
    {
      key: 10,
      title: "故障管理",
      num: 0,
    },
    {
      key: 4,
      title: "提现申请",
      num: 0,
    },
  ],
  totalNum: 0,
};

const state = {
  todonum: {
    ...todonumObj,
  },
};

const getters = {
  todoTypelist(state, getters, rootState) {
    if (rootState.company.company.id != 2) {
      return state.todonum.todolist.filter((item) => {
        return item.key != 4;
      });
    }
    return state.todonum.todolist;
  },
};

const mutations = {
  setTodoNum: (state, todonumObj) => {
    state.todonum = todonumObj;
  },
};

const actions = {
  async getTodoNum({ commit }) {
    const { result } = await _post({
      url: Config.apiUrl.getWithDoneDealNum,
      showLoading: false,
    });
    if (result.code === 0) {
      todonumObj.todolist.map((oitem) => {
        oitem.num = 0;
      });
      result.data.result?.map((item) => {
        todonumObj.todolist.map((oitem) => {
          if (oitem.key == item.keyType) {
            oitem.num = item.num;
          }
        });
      });
      todonumObj.totalNum = result.data.totalNum;
      commit("setTodoNum", todonumObj);
      return todonumObj;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
