import Vue from "vue";
import Vuex from "vuex";

import company from "./modules/company";
import hamburger from "./modules/hamburger";
import menu from "./modules/menu.js";
import newsnum from "./modules/news.js";
import tagsView from "./modules/tagsView";
import todonum from "./modules/todonum.js";
import user from "./modules/user";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    company,
    tagsView,
    menu,
    todonum,
    newsnum,
    hamburger,
  },
});

export default store;
