function forMatNum(num) {
  return num < 10 ? "0" + num : num + "";
}

function initDays(year, month) {
  const totalDays = new Date(year, month, 0).getDate();
  const dates = [];
  for (let d = 1; d <= totalDays; d++) {
    dates.push(forMatNum(d));
  }
  return dates;
}
function initPicker(start, end, mode = "date", step) {
  const aToday = new Date();
  const tYear = aToday.getFullYear().toString();
  const tMonth = forMatNum(aToday.getMonth() + 1).toString();
  const tDay = forMatNum(aToday.getDate()).toString();
  const tHours = forMatNum(aToday.getHours()).toString();
  const tMinutes = forMatNum(aToday.getMinutes()).toString();
  const tSeconds = forMatNum(aToday.getSeconds()).toString();
  let initstartDate = new Date(start);
  let endDate = new Date(end);
  if (start > end) {
    initstartDate = new Date(end);
    endDate = new Date(start);
  }
  const startYear = initstartDate.getFullYear();
  const startMonth = initstartDate.getMonth();
  const endYear = endDate.getFullYear();
  const years = [],
    months = [],
    days = [],
    hours = [],
    minutes = [],
    seconds = [];
  let defaultVal = [];
  const totalDays = new Date(startYear, startMonth, 0).getDate();
  for (let s = startYear; s <= endYear; s++) {
    years.push(s + "");
  }
  for (let m = 1; m <= 12; m++) {
    months.push(forMatNum(m));
  }
  for (let d = 1; d <= totalDays; d++) {
    days.push(forMatNum(d));
  }
  for (let h = 0; h < 24; h++) {
    hours.push(forMatNum(h));
  }
  for (let m = 0; m < 60; m += step * 1) {
    minutes.push(forMatNum(m));
  }
  for (let s = 0; s < 60; s++) {
    seconds.push(forMatNum(s));
  }
  switch (mode) {
    case "date":
      defaultVal = [years.indexOf(tYear), months.indexOf(tMonth), days.indexOf(tDay)];
      return { years, months, days, defaultVal };
    case "yearMonth":
      defaultVal = [years.indexOf(tYear), months.indexOf(tMonth)];
      return { years, months, defaultVal };
    case "dateTime":
      defaultVal = [
        years.indexOf(tYear),
        months.indexOf(tMonth),
        days.indexOf(tDay),
        hours.indexOf(tHours),
        minutes.indexOf(tMinutes),
        seconds.indexOf(tSeconds),
      ];
      return { years, months, days, hours, minutes, seconds, defaultVal };
    case "time":
      defaultVal = [hours.indexOf(tHours), minutes.indexOf(tMinutes), seconds.indexOf(tSeconds)];
      return { hours, minutes, seconds, defaultVal };
  }
}

function copyData(target) {
  //先判断类型，再拷贝
  function checkType(val) {
    return Object.prototype.toString.call(val).slice(8, -1);
  }
  let res;
  const type = checkType(target);
  if (type === "Object") {
    res = {};
  } else if (type === "Array") {
    res = [];
  } else {
    return target;
  }
  for (const i in target) {
    //数组和对象 for in 循环
    const value = target[i];
    if (value && (checkType(value) === "Object" || checkType(value) === "Array")) {
      //嵌套
      res[i] = this.copyData(value);
    } else {
      //基本数据或者函数
      res[i] = value;
    }
  }
  return res;
}

function formatDate(date, fmt) {
  const o = {
    "Y+": date.getFullYear(), //年份
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

function easyformatDate(data) {
  const nDate = data ? new Date(Number(data)) : new Date();

  const year = nDate.getFullYear();
  let month = nDate.getMonth() + 1;
  let day = nDate.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  day = day < 10 ? "0" + day : day;

  return year + "-" + month + "-" + day;
}

function allformatDate(data) {
  const nDate = data ? new Date(Number(data)) : new Date();

  const year = nDate.getFullYear();
  let month = nDate.getMonth() + 1;
  let day = nDate.getDate();
  let hours = nDate.getHours();
  let minuts = nDate.getMinutes();
  let seconds = nDate.getSeconds();
  //return year +'/'+ month +'/'+ day+' '+hours+ ':'+minuts+':'+seconds
  if (month < 10) {
    month = "0" + month;
  }
  day = day < 10 ? "0" + day : day;
  hours = hours < 10 ? "0" + hours : hours;
  minuts = minuts < 10 ? "0" + minuts : minuts;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return year + "." + month + "." + day + " " + hours + ":" + minuts + ":" + seconds;
}

function formatLocation(longitude, latitude) {
  if (typeof longitude === "string" && typeof latitude === "string") {
    longitude = parseFloat(longitude);
    latitude = parseFloat(latitude);
  }

  longitude = longitude.toFixed(2);
  latitude = latitude.toFixed(2);

  return {
    longitude: longitude.toString().split("."),
    latitude: latitude.toString().split("."),
  };
}

/**
 * 格式化数字,以万千为单位展示,保留1位小数点
 * @param {Object} num
 */
function formatNumCn(num) {
  if (num == undefined) {
    return 0;
  }
  if (num > 1000 && num < 10000) {
    return Math.round(num / 1000, 1) + "千";
  }
  if (num > 10000) {
    return Math.round(num / 10000, 1) + "万";
  }
  return num;
}

function rpad(str, padstr, length) {
  str += "";
  while (str.length < length) {
    str = str + "" + padstr;
  }
  return str;
}

function lpad(str, padstr, length) {
  str += "";
  while (str.length < length) {
    str = padstr + "" + str;
  }
  return str;
}

//格式化金额
function formatMoney(amount) {
  if (isNaN(amount)) {
    return "0";
  }
  if (amount !== undefined) {
    if (amount % 100 === 0) {
      return parseInt(amount / 100);
    } else {
      return (amount / 100).toFixed(2);
    }
  } else {
    return "0";
  }
}

//添加金额千分位分隔符

function numFormat(a) {
  if (!a) {
    return "0.00";
  }
  let num = Number(a);
  if (!num) {
    //等于0
    return num + ".00";
  } else {
    //不等于0
    num = Math.round(num * 100) / 10000;
    num = num.toFixed(2);
    num += ""; //转成字符串
    const reg = num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g; //千分符的正则
    // console.log(num.indexOf('.')>-1)
    return num.replace(reg, "$1,"); //千分位格式化
  }
}

/**
 * 转换年月日方法(暂时支持以下两种,如果需要其他格式,自己再拼接)
 * format :yyyy-mm-dd，yyyy/mm/dd
 * str : 时间戳
 */
function getDate(format, str) {
  const oDate = new Date(str);
  const oYear = oDate.getFullYear();
  const oMonth = oDate.getMonth() + 1;
  const oDay = oDate.getDate();

  if (format == "yyyy-mm-dd") {
    return oYear + "-" + getzf(oMonth) + "-" + getzf(oDay); //最后拼接时间
  } else if (format == "yyyy/mm/dd") {
    return oYear + "/" + getzf(oMonth) + "/" + getzf(oDay); //最后拼接时间
  }
}
//补0操作
function getzf(num) {
  if (parseInt(num) < 10) {
    num = "0" + num;
  }
  return num;
}

function formatAddress(address) {
  if (!address) {
    return "";
  }
  return address
    .replace("北京市北京市", "北京市")
    .replace("上海市上海市", "上海市")
    .replace("天津市天津市", "天津市")
    .replace("重庆市重庆市", "重庆市");
}
// 价格输入限制
function checkMoney(value) {
  value = value.replace(/[^\d.]/g, "");
  //必须保证第一个为数字而不是.
  value = value.replace(/^\./g, "");
  //保证只有出现一个.而没有多个.
  value = value.replace(/\.{2,}/g, ".");
  //保证.只出现一次，而不能出现两次以上
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  //只能输入两位小数
  value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
  return value;
}

function download(url) {
  window.location.href = url;
  // fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
  // 	const a = document.createElement('a')
  // 	a.href = URL.createObjectURL(blob)
  // 	//测试链接console.log(a.href)
  // 	a.download = fileName  // 下载文件的名字
  // 	document.body.appendChild(a)
  // 	a.click()
  // })
}

export default {
  initDays,
  initPicker,
  copyData,
  formatDate,
  easyformatDate,
  formatLocation,
  formatNumCn,
  rpad,
  lpad,
  formatMoney,
  getDate,
  numFormat,
  allformatDate,
  formatAddress,
  download,
  checkMoney,
};
