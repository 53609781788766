import { _get } from "@/axios/func";
import Config from "@/config";

const newsnumObj = {
  newslist: [
    {
      keyType: "",
      notReadNum: 0,
    },
  ],
  totalNum: 0,
};
const state = {
  newsnum: {
    ...newsnumObj,
  },
};

const mutations = {
  setNewsNum: (state, newsnumObj) => {
    state.newsnum = newsnumObj;
  },
};

const actions = {
  async getNewsNum({ commit }) {
    const arg = {
      url: Config.apiUrl.getNotifyNum,
      params: {},
      showLoading: false,
    };
    const { result } = await _get(arg);

    if (result.code === 0) {
      const data = result.data;
      if (!!data.notifyKeyTypeRes && data.notifyKeyTypeRes.length) {
        newsnumObj.newslist = data.notifyKeyTypeRes;
        newsnumObj.totalNum = data.notifyNotReadTotalNum;
      } else {
        newsnumObj.newslist = [
          {
            keyType: "",
            notReadNum: 0,
          },
        ];
        newsnumObj.totalNum = 0;
      }

      commit("setNewsNum", newsnumObj);
      return newsnumObj;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
