<template>
  <el-dialog title="选择项目收益" :visible.sync="dialogVisible">
    <div class="a-flex-rfsc">
      <le-input-icon-search
        v-model="pageParam.params.key"
        placeholder="请输入方案名称"
      ></le-input-icon-search>
      <le-company-under-select
        v-model="pageParam.params.companyId"
        placeholder="请输入所属方"
        :readonly="companyId != undefined"
      ></le-company-under-select>
    </div>
    <div class="a-mt-24">
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table :data="projectList" height="425">
          <el-table-column property="name" label="项目名称"></el-table-column>
          <el-table-column property="companyName" label="所属商户"></el-table-column>
          <el-table-column property="stateText" label="项目状态"></el-table-column>
          <el-table-column property="createTime" label="创建时间"></el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="{ row }">
              <el-button type="info" plain @click="handlerChooseIncome(row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </div>
  </el-dialog>
</template>

<script>
/**
 * 现勘选择
 */
export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
      default: false,
    },
    incomeId: {
      required: false,
    },
    incomeName: {
      required: false,
    },
    status: {
      type: Number,
    },
    companyId: {
      type: Number,
    },
  },
  data() {
    return {
      dialogVisible: false,

      pageParam: {
        url: this.$Config.apiUrl.getDistriSelectList,
        method: "post",
        params: {
          key: "",
          companyId: "",
          status: this.status,
        },
      },
      projectList: [],
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
    },
    dialogVisible: {
      handler(val, oldval) {
        if (val != oldval) {
          this.$emit("update:visible", val);
        }
      },
    },
    companyId: {
      handler(val) {
        this.pageParam.params.companyId = val;
      },
    },
  },
  mounted() {},
  methods: {
    handlerChooseIncome(item) {
      this.dialogVisible = false;
      this.$emit("update:incomeId", item.id);
      this.$emit("update:incomeName", item.name);
    },
    setTableData(data) {
      this.projectList = data;
    },
  },
};
</script>
