<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span
          v-if="item.redirect === 'noredirect' || index == levelList.length - 1"
          class="no-redirect"
        >
          {{ item.title }}
        </span>
        <a v-else @click.prevent="handleLink(item)">{{ item.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from "path-to-regexp";
import { mapState } from "vuex";

export default {
  data() {
    return {
      levelList: null,
    };
  },

  computed: {
    ...mapState({
      routeHis: (state) => state.menu.routeHis,
    }),
  },

  created() {
    this.getBreadcrumb();
  },

  methods: {
    getBreadcrumb() {
      if (this.routeHis.length <= 2) {
        this.levelList = this.routeHis.map((item) => {
          return {
            title: item.meta.title,
            path: item.path,
            redirect: item.redirect,
            fullPath: item.fullPath,
          };
        });
      } else {
        this.levelList = this.routeHis.slice(this.routeHis.length - 2).map((item) => {
          return {
            title: item.meta.title,
            path: item.path,
            redirect: item.redirect,
            fullPath: item.fullPath,
          };
        });
      }
      this.levelList = this.levelList.filter((v) => !!v.title);
    },
    pathCompile(path) {
      const { params } = this.$route;
      const toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, fullPath } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(fullPath);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
