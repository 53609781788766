<template>
  <div class="a-flex-rfsc a-ptb-12">
    <div v-show="label" class="s-search-label a-flex-rfec">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <span>{{ label }}</span>
    </div>
    <el-date-picker
      v-model="value1"
      :value-format="valueFormat"
      class="date-range a-ml-20"
      :type="type"
      range-separator="-"
      :class="large ? 'date-range-large' : 'date-range'"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :picker-options="pickerOptions"
      :default-time="defaultTime"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    minDate: {
      required: false,
      type: String,
      validator: (param) => {
        if (param && !param.match(/\d{4}-[01]{1}\d{1}-[0,1,2,3]{1}\d{1}( \d{2}:\d{2}:\d{2})?/)) {
          return false;
        } else {
          return true;
        }
      },
    },
    maxDate: {
      required: false,
      type: String,
      validator: (param) => {
        if (param && !param.match(/\d{4}-[01]{1}\d{1}-[0,1,2,3]{1}\d{1}( \d{2}:\d{2}:\d{2})?/)) {
          return false;
        } else {
          return true;
        }
      },
    },
    type: {
      type: String,
      default: "daterange",
    },
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd",
    },
    startPlaceholder: {
      required: false,
      type: String,
      default: "开始日期",
    },
    endPlaceholder: {
      required: false,
      type: String,
      default: "结束日期",
    },
    /**
     * 是否必填
     */
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    pickerOptions: {
      type: Object,
      default: () => ({}),
    },
    defaultTime: {
      type: Array,
      default: () => ["00:00:00", "23:59:59"],
    },
  },
  computed: {
    value1: {
      get() {
        return [this.minDate, this.maxDate];
      },
      set(val) {
        this.$emit("update:minDate", val ? val[0] : "");
        this.$emit("update:maxDate", val ? val[1] : "");
      },
    },
  },
};
</script>

<style scoped>
.date-range {
  width: 240px;
}

.date-range .el-range-input {
  width: 90px;
}

.date-range-large {
  width: 376px;
}

.date-range-large .el-range-input {
  width: 108px;
}
</style>
