<template>
  <div class="navbar">
    <div class="a-flex-rsbc" style="height: 64px">
      <div class="a-ml-25 a-flex-rcc">
        <span class="a-fs-24 a-ff-zihun" style="color: #414345">{{ config.brand }}管理系统</span>
        <hamburger></hamburger>
        <!-- <img src="../../../assets/icon/nav-menu.png" class="a-btn-img a-wh-16 a-ml-30" /> -->
      </div>
      <div class="a-flex-rcc a-c-333 a-plr-40">
        <img src="../../../assets/icon/nav-home.png" class="a-btn-img a-wh-16" />
        <span class="a-fs-14 a-ml-08 font-point" @click="toBusinessCenter">商户中心</span>
        <img
          v-if="userInfo.avatarUrl"
          :src="userInfo.avatarUrl"
          class="a-btn-img a-wh-32 a-ml-40 a-br-25"
        />
        <img v-else src="../../../assets/icon/avatarUrlimg.png" alt="" class="avatarUrlimg" />
        <span class="a-fs-14 a-ml-08">{{ userInfo.realName }}</span>
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <img src="../../../assets/icon/nav-down.png" class="a-btn-img a-wh-16 a-ml-08" />
          </span>
          <el-dropdown-menu slot="dropdown" class="dropdownbox">
            <el-dropdown-item icon="el-icon-my-tuichudenglu" command="goout">退出</el-dropdown-item>
            <el-dropdown-item icon="el-icon-my-xiugaimima" command="editpassword">
              修改密码
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog title="修改密码" append-to-body :visible.sync="changeShow" width="400px">
      <div>
        <el-form ref="pwdForm" :model="pwdForm" :rules="rules">
          <el-form-item label="新密码" prop="password" label-width="150">
            <el-input v-model="pwdForm.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="新密码确认" prop="password_two" label-width="150">
            <el-input v-model="pwdForm.password_two" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeShow = false">取 消</el-button>
        <el-button type="primary" @click="cancelChangePwd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Hamburger from "@/components/Hamburger/index";

import config from "../../../config/index.js";
import util from "../../../utils/index";
export default {
  components: {
    Hamburger,
  },
  data() {
    const validatepwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认密码"));
      } else if (value != this.pwdForm.password) {
        callback(new Error("两次密码输入不同"));
      } else {
        callback();
      }
    };
    return {
      config: config,
      changeShow: false, //修改密码
      pwdForm: {
        password: "",
        password_two: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        password_two: [
          {
            required: true,
            validator: validatepwd,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user,
      newsnum: (state) => state.newsnum.newsnum,
      company: (state) => state.company.company,
    }),
  },

  methods: {
    ...mapActions({
      gooutLogin: "user/logout",
    }),
    toBusinessCenter() {
      this.$router.push({
        path: "/businessCenter/business-center",
      });
    },
    handleCommand(command) {
      if (command == "goout") {
        this.gooutLogin().then(() => {
          setTimeout(() => {
            this.$router.replace({
              path: "/login",
            });
          }, 1000);
        });
      } else if (command == "editpassword") {
        this.changeShow = true;
      }
    },
    //修改密码
    cancelChangePwd() {
      this.$refs["pwdForm"].validate(async (valid) => {
        if (valid) {
          this.$Axios
            ._get({
              url: this.$Config.apiUrl.resetUserPassword,
              params: {
                userId: this.userInfo.id,
                companyId: this.company.id,
                password: this.pwdForm.password,
              },
            })
            .then((res) => {
              if (res.result.code == 0) {
                this.$message.success("修改成功!");
                util.removeCookie("password");
                this.changeShow = false;
                this.gooutLogin().then(() => {
                  setTimeout(() => {
                    this.$router.replace({
                      path: "/login",
                    });
                  }, 1000);
                });
              } else {
                this.$message.error(res.result.message);
              }
            });
        }
      });
    },
    toggleClick() {
      this.$store.commit("hamburger/triggerSidebarOpened");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  width: 100%;
  height: 64px;

  top: 0;
  left: 0;
  background: #fff;
  right: 0;
  z-index: 10;
}

.dropdownbox {
  width: 112px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 0;

  /deep/ .el-dropdown-menu__item {
    padding: 6px 16px;
  }

  /deep/ .el-dropdown-menu__item:hover {
    background: #f2f8ff;
  }
}

/deep/ .el-dropdown-menu {
  padding: 0;
}

.ptb-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.itembadge /deep/ .el-badge__content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #ff3b30;
  margin-left: 8px;
  top: 0;
}

.avatarUrlimg {
  height: 32px;
  width: 32px;
  margin-left: 40px;
}
</style>
