import { C as COMPANY_CHARGE_PLAN_CONST, a as COMMON_CONST, b as CHARGE_PLAN_OPERATION_LOG_CONST, O as ORDER_CONST } from './shared/common.33d13842.mjs';
export { t as throttle } from './shared/common.33d13842.mjs';
import Big from 'big.js';
export { default as Big } from 'big.js';
import dayjs from 'dayjs';
export { default as dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { omit } from 'lodash-es';

class CompanyConst {
  constructor() {
    this.rechargeStatus = {
      created: 0,
      placeOrderSuccess: 1,
      cancel: 2,
      paySuccess: 3,
      partialRefunded: 4,
      refunded: 5,
      placeOrderFail: 6,
      refunding: 7
    };
    this.rechargeType = {
      balance: 0,
      physicalCard: 4
    };
  }
  get rechargeTypeMap() {
    return {
      [this.rechargeType.balance]: "\u4F59\u989D",
      [this.rechargeType.physicalCard]: "\u5B9E\u4F53\u5361"
    };
  }
}
const COMPANY_CONST = new CompanyConst();

class CompanyMealCardConst {
  constructor() {
    this.chargeNumType = {
      unlimited: 1,
      custom: 2
    };
    this.type = {
      month: 1,
      quarterly: 2,
      year: 3,
      custom: 4
    };
    this.refundStatus = {
      unRefunded: 1,
      refunded: 2
    };
  }
  get typeMap() {
    return {
      [this.type.month]: "\u6708\u5361",
      [this.type.quarterly]: "\u5B63\u5361",
      [this.type.year]: "\u5E74\u5361",
      [this.type.custom]: "\u81EA\u5B9A\u4E49"
    };
  }
}
const COMPANY_MEAL_CARD_CONST = new CompanyMealCardConst();

class CompanyRechargeConst {
  constructor() {
    this.rechargeStatus = {
      created: 0,
      placeOrderSuccess: 1,
      cancel: 2,
      paySuccess: 3,
      partialRefunded: 4,
      refunded: 5,
      placeOrderFail: 6,
      refunding: 7
    };
    this.rechargeType = {
      balance: 0,
      physicalCard: 4
    };
  }
  get rechargeTypeMap() {
    return {
      [this.rechargeType.balance]: "\u4F59\u989D",
      [this.rechargeType.physicalCard]: "\u5B9E\u4F53\u5361"
    };
  }
}
const COMPANY_RECHARGE_CONST = new CompanyRechargeConst();

class YunstConst {
  constructor() {
    this.memberType = {
      company: 2,
      natural: 3
    };
    this.identityStatus = {
      certificationPending: 0,
      certificationSuccess: 1,
      certificationFail: 2
    };
  }
  get memberMap() {
    return {
      [this.memberType.company]: "\u4F01\u4E1A",
      [this.memberType.natural]: "\u4E2A\u4EBA"
    };
  }
  get identityStatusLabelList() {
    return {
      [this.identityStatus.certificationSuccess]: {
        label: "\u8BA4\u8BC1\u6210\u529F",
        color: "#00C29F"
      },
      [this.identityStatus.certificationFail]: {
        label: "\u8BA4\u8BC1\u5931\u8D25",
        color: "#F71B1B"
      },
      [this.identityStatus.certificationPending]: {
        label: "\u5F85\u8BA4\u8BC1",
        color: "#FCA71A"
      }
    };
  }
  get bankList() {
    return [
      { label: "\u897F\u5B89\u94F6\u884C" },
      { label: "\u9F50\u9C81\u94F6\u884C" },
      { label: "\u6C49\u53E3\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u94F6\u884C" },
      { label: "\u5E7F\u53D1\u94F6\u884C" },
      { label: "\u4EA4\u901A\u94F6\u884C" },
      { label: "\u6D66\u4E1C\u53D1\u5C55\u94F6\u884C" },
      { label: "\u5927\u8FDE\u94F6\u884C" },
      { label: "\u8D35\u9633\u94F6\u884C" },
      { label: "\u5EFA\u8BBE\u94F6\u884C" },
      { label: "\u534E\u590F\u94F6\u884C" },
      { label: "\u6CB3\u5317\u94F6\u884C" },
      { label: "\u70DF\u53F0\u94F6\u884C" },
      { label: "\u6F4D\u574A\u94F6\u884C" },
      { label: "\u67A3\u5E84\u94F6\u884C" },
      { label: "\u5E73\u5B89\u94F6\u884C" },
      { label: "\u676D\u5DDE\u94F6\u884C" },
      { label: "\u676D\u5DDE\u8054\u5408\u94F6\u884C" },
      { label: "\u9A7B\u9A6C\u5E97\u94F6\u884C" },
      { label: "\u5317\u4EAC\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u4E1C\u839E\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u519C\u4FE1\u798F\u5EFA" },
      { label: "\u519C\u4FE1\u5B89\u5FBD" },
      { label: "\u519C\u4FE1\u5C71\u4E1C" },
      { label: "\u767D\u57CE\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6CF0\u5B89\u5546\u884C" },
      { label: "\u519C\u4FE1\u6CB3\u5357" },
      { label: "\u65E5\u7167\u94F6\u884C" },
      { label: "\u5A01\u6D77\u94F6\u884C" },
      { label: "\u4E1C\u8425\u94F6\u884C" },
      { label: "\u6D4E\u5B81\u94F6\u884C" },
      { label: "\u6E24\u6D77\u94F6\u884C" },
      { label: "\u6052\u4E30\u94F6\u884C" },
      { label: "\u5FB7\u5DDE\u94F6\u884C" },
      { label: "\u5C71\u4E1C\u519C\u4FE1\u793E" },
      { label: "\u4E34\u5546\u94F6\u884C" },
      { label: "\u9752\u5C9B\u94F6\u884C" },
      { label: "\u4E0A\u6D77\u94F6\u884C" },
      { label: "\u5E7F\u897F\u519C\u4FE1\u793E" },
      { label: "\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u664B\u4E2D\u5E02\u5546\u4E1A\u94F6\u884C" },
      { label: "\u4E0A\u9976\u5E02\u5546\u4E1A\u94F6\u884C" },
      { label: "\u519C\u4FE1\u5185\u8499" },
      { label: "\u519C\u4FE1\u6E56\u5357" },
      { label: "\u6CF0\u5B89\u5E02\u5546\u4E1A\u94F6\u884C\u65B0\u533A\u652F\u884C" },
      { label: "\u5B89\u5FBD\u8499\u57CE\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5357\u4EAC\u94F6\u884C" },
      { label: "\u5B81\u6CE2\u94F6\u884C" },
      { label: "\u5317\u4EAC\u94F6\u884C" },
      { label: "\u5149\u5927\u94F6\u884C" },
      { label: "\u804A\u57CE\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u94A6\u5DDE\u5E02\u94A6\u5357\u56FD\u6C11\u6751\u9547\u94F6\u884C" },
      { label: "\u62DB\u5546\u94F6\u884C" },
      { label: "\u519C\u4E1A\u94F6\u884C" },
      { label: "\u6210\u90FD\u5546\u4E1A\u94F6\u884C" },
      { label: "\u4E2D\u4FE1\u94F6\u884C" },
      { label: "\u6C11\u751F\u94F6\u884C" },
      { label: "\u8FDE\u4E91\u6E2F\u4E1C\u65B9\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u519C\u6751\u6751\u9547\u94F6\u884C" },
      { label: "\u83B1\u5546\u94F6\u884C" },
      { label: "\u9526\u5DDE\u94F6\u884C" },
      { label: "\u90D1\u5DDE\u5E02\u5E02\u90CA\u519C\u6751\u4FE1\u7528\u5408\u4F5C\u8054\u793E" },
      { label: "\u6606\u4ED1\u94F6\u884C" },
      { label: "\u6E56\u5317\u519C\u4FE1" },
      { label: "\u6210\u90FD\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5929\u6D25\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6C5F\u82CF\u7D2B\u91D1\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6D66\u5317\u56FD\u6C11\u6751\u9547\u94F6\u884C" },
      { label: "\u94A6\u5357\u56FD\u6C11\u6751\u9547\u94F6\u884C" },
      { label: "\u6C5F\u897F\u8D63\u5DDE\u94F6\u5EA7\u6751\u9547\u94F6\u884C" },
      { label: "\u7126\u4F5C\u4E2D\u65C5\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u7518\u8083\u94F6\u884C" },
      { label: "\u6E56\u5317\u4E09\u5CE1\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u53F0\u5DDE\u94F6\u884C" },
      { label: "\u6C5F\u82CF\u957F\u6C5F\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6B66\u6C49\u4F17\u90A6\u94F6\u884C" },
      { label: "\u65B0\u7586\u7EF4\u543E\u5C14\u81EA\u6CBB\u533A\u519C\u6751\u4FE1\u7528\u793E\u8054\u5408" },
      { label: "\u90D1\u5DDE\u94F6\u884C" },
      { label: "\u65B0\u7586\u77F3\u6CB3\u5B50\u4EA4\u94F6\u6751\u9547\u94F6\u884C" },
      { label: "\u5185\u8499\u519C\u4FE1" },
      { label: "\u6C5F\u897F\u94F6\u884C" },
      { label: "\u4E39\u4E1C\u94F6\u884C" },
      { label: "\u6606\u5C71\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u519C\u4FE1\u8FBD\u5B81" },
      { label: "\u798F\u5EFA\u6D77\u5CE1\u94F6\u884C" },
      { label: "\u5305\u5546\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8\u978D\u5C71\u9053\u652F\u884C" },
      { label: "\u6210\u90FD\u94F6\u884C" },
      { label: "\u957F\u6CBB\u94F6\u884C" },
      { label: "\u957F\u6C99\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5927\u8FDE\u519C\u5546\u884C" },
      { label: "\u5357\u5145\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u9752\u5C9B\u519C\u5546\u94F6\u884C" },
      { label: "\u6CF8\u5DDE\u5546\u4E1A\u94F6\u884C" },
      { label: "\u91CD\u5E86\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5E73\u9876\u5C71\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6F33\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u9F99\u6C5F\u94F6\u884C" },
      { label: "\u5B89\u5FBD\u5929\u957F\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u8FBD\u9633\u94F6\u884C" },
      { label: "\u9752\u5C9B\u80F6\u5357\u6D77\u6C47\u6751\u9547\u94F6\u884C" },
      { label: "\u4E50\u5C71\u5E02\u5546\u4E1A\u94F6\u884C" },
      { label: "\u4F01\u4E1A\u94F6\u884C" },
      { label: "\u5FBD\u5546\u94F6\u884C" },
      { label: "\u6751\u9547\u94F6\u884C\u6709\u9650\u516C\u53F8" },
      { label: "\u627F\u5FB7\u94F6\u884C" },
      { label: "\u6D1B\u9633\u94F6\u884C" },
      { label: "\u8D35\u5DDE\u94F6\u884C" },
      { label: "\u5C71\u4E1C\u6881\u5C71\u6C11\u4E30\u6751\u9547\u94F6\u884C" },
      { label: "\u4E50\u5C71\u94F6\u884C" },
      { label: "\u53F0\u6C5F\u5BCC\u6C11\u6751\u9547\u94F6\u884C" },
      { label: "\u8D35\u9633\u4E91\u5CA9\u5BCC\u6C11\u6751\u9547\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u519C\u4E1A\u53D1\u5C55\u94F6\u884C" },
      { label: "\u5408\u80A5\u79D1\u6280\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5317\u4EAC\u987A\u4E49\u94F6\u5EA7\u6751\u9547\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u5EFA\u8BBE\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u519C\u4E1A\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u519C\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u4E2D\u56FD\u6C11\u751F\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u90AE\u50A8\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u5DE5\u5546\u94F6\u884C" },
      { label: "\u6D66\u53D1\u94F6\u884C" },
      { label: "\u9752\u6D77\u94F6\u884C" },
      { label: "\u4E0A\u6D77\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u8D35\u5B9A\u6052\u751F\u6751\u9547\u94F6\u884C" },
      { label: "\u6069\u65BD\u5174\u798F\u6751\u9547\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u94F6\u6D77\u56FD\u6C11\u6751\u9547\u94F6\u884C" },
      { label: "\u5510\u5C71\u94F6\u884C" },
      { label: "\u957F\u57CE\u534E\u897F\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u7F8E\u56FD\u94F6\u884C\u6709\u9650\u516C\u53F8" },
      { label: "\u5C71\u4E1C\u83B1\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u77F3\u6CB3\u5B50\u56FD\u6C11\u6751\u9547\u94F6\u884C" },
      { label: "\u81EA\u8D21\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6C5F\u82CF\u5434\u6C5F\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5FB7\u9633\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6C47\u4E30\u94F6\u884C" },
      { label: "\u534E\u5546\u94F6\u884C" },
      { label: "\u6D59\u6C5F\u6C11\u6CF0\u5546\u4E1A\u94F6\u884C" },
      { label: "\u7EF5\u9633\u5546\u884C" },
      { label: "\u6C5F\u897F\u519C\u4FE1" },
      { label: "\u5E7F\u4E1C\u5357\u7CA4\u94F6\u884C" },
      { label: "\u5305\u5546\u94F6\u884C" },
      { label: "\u54C8\u5C14\u6EE8\u5E02\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6DF1\u5733\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u519C\u4FE1\u94F6" },
      { label: "\u4E2D\u539F\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u957F\u5B89\u94F6\u884C" },
      { label: "\u5B81\u590F\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5609\u5174\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u4E4C\u9C81\u6728\u9F50\u5E02\u5546\u4E1A\u94F6\u884C" },
      { label: "\u9752\u5C9B\u5373\u58A8\u60E0\u6C11\u6751\u9547\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6F4D\u574A\u519C\u6751\u5546\u4E1A\u94F6\u884C\u4E1C\u660E\u652F\u884C" },
      { label: "\u534E\u6DA6\u94F6\u884C" },
      { label: "\u5F20\u5BB6\u53E3\u94F6\u884C" },
      { label: "\u82CF\u5DDE\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u82CF\u5DDE\u94F6\u884C" },
      { label: "\u901A\u8054\u8DE8\u5883" },
      { label: "\u9102\u5C14\u591A\u65AF\u94F6\u884C" },
      { label: "\u5185\u8499\u53E4\u94F6\u884C" },
      { label: "\u5929\u6D25\u6EE8\u6D77\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u53A6\u95E8\u94F6\u884C" },
      { label: "\u4E4C\u9C81\u6728\u9F50\u94F6\u884C" },
      { label: "\u4E5D\u6C5F\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6606\u5C71\u519C\u4FE1\u793E" },
      { label: "\u6CF0\u9686\u57CE\u5E02\u4FE1\u7528\u793E" },
      { label: "\u65E0\u9521\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u53A6\u95E8\u519C\u4FE1" },
      { label: "\u56DB\u5DDD\u6E20\u53BF\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u519C\u4FE1\u6D59\u6C5F" },
      { label: "\u4E2D\u56FD\u5149\u5927\u94F6\u884C" },
      { label: "\u664B\u4E2D\u94F6\u884C" },
      { label: "\u4E2D\u94F6\u5BCC\u767B\u6751\u9547\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u901A\u8054\u652F\u4ED8-\u5907\u4ED8\u91D1" },
      { label: "\u629A\u987A\u94F6\u884C" },
      { label: "\u6DF1\u5733\u5B9D\u5B89\u6842\u94F6\u94F6\u884C" },
      { label: "\u6C5F\u95E8\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u66F2\u9756\u5E02\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u901A\u8054\u901A" },
      { label: "\u519C\u6751\u4FE1\u7528\u793E" },
      { label: "\u8D35\u5DDE\u7701\u519C\u6751\u4FE1\u7528\u793E\u8054\u5408\u793E" },
      { label: "\u5E7F\u4E1C\u7701\u519C\u6751\u4FE1\u7528\u793E\u8054\u5408\u793E" },
      { label: "\u672C\u6EAA\u5E02\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6D59\u6C5F\u7ECD\u5174\u745E\u4E30\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6B66\u6C49\u4F17\u90A6\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "04010000" },
      { label: "\u897F\u85CF\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u8D63\u5DDE\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u519C\u6751\u5408\u4F5C\u94F6\u884C" },
      { label: "\u5FAE\u4F17\u94F6\u884C" },
      { label: "\u6C5F\u897F\u519C\u5546\u94F6\u884C" },
      { label: "\u56FD\u5BB6\u5F00\u53D1\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u8FDB\u51FA\u53E3\u94F6\u884C" },
      { label: "\u91CD\u5E86\u5BCC\u6C11\u94F6\u884C" },
      { label: "\u5409\u6797\u4EBF\u8054\u94F6\u884C" },
      { label: "\u5927\u534E\u94F6\u884C\uFF08\u4E2D\u56FD\uFF09" },
      { label: "\u534E\u4FA8\u6C38\u4EA8\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u97E9\u4E9A\u94F6\u884C\uFF08\u4E2D\u56FD\uFF09" },
      { label: "\u534E\u878D\u6E58\u6C5F\u94F6\u884C" },
      { label: "\u6E56\u5357\u4E09\u6E58\u94F6\u884C" },
      { label: "\u5409\u6797\u94F6\u884C" },
      { label: "\u5E7F\u4E1C\u6F84\u6D77\u6F6E\u5546\u6751\u9547\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8 " },
      { label: "\u5174\u4E1A\u94F6\u884C" },
      { label: "\u6E56\u5317\u94F6\u884C" },
      { label: "\u90AF\u90F8\u94F6\u884C" },
      { label: "\u5170\u5DDE\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5929\u6D25\u94F6\u884C" },
      { label: "\u5929\u6D25\u5E02\u5546\u4E1A\u94F6\u884C" },
      { label: "\u91CD\u5E86\u4E09\u5CE1\u94F6\u884C" },
      { label: "\u8D35\u5DDE\u9075\u4E49\u6C47\u5DDD\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u77F3\u5BB6\u5E84\u6C47\u878D\u519C\u6751\u5408\u4F5C\u94F6\u884C" },
      { label: "\u91CD\u5E86\u94F6\u884C" },
      { label: "\u8BF8\u57CE\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u9752\u6D77\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5A01\u6D77\u5E02\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5C71\u897F\u957F\u6CBB\u9ECE\u90FD\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u9752\u6D77\u683C\u5C14\u6728\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6D77\u53E3\u8054\u5408\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5A01\u6D77\u84DD\u6D77\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u957F\u6625\u53D1\u5C55\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u8D35\u5DDE\u9ECE\u5E73\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u8499\u9634\u9F50\u4E30\u6751\u9547\u94F6\u884C" },
      { label: "\u9F50\u9C81\u6751\u9547\u94F6\u884C" },
      { label: "\u6D59\u6C5F\u7F51\u5546\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u8FBD\u6C88\u94F6\u884C" },
      { label: "\u79B9\u5DDE\u5E02\u519C\u6751\u4FE1\u7528\u5408\u4F5C\u8054\u793E" },
      { label: "\u5E73\u548C\u6DA6\u4E30\u6751\u9547\u94F6\u884C" },
      { label: "\u664B\u5546\u94F6\u884C" },
      { label: "\u76DB\u4EAC\u94F6\u884C" },
      { label: "\u6842\u6797\u94F6\u884C" },
      { label: "\u90D1\u5DDE\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u9F50\u5546\u94F6\u884C" },
      { label: "\u6C5F\u82CF\u94F6\u884C" },
      { label: "\u6D59\u6C5F\u6CF0\u9686\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5E7F\u897F\u5317\u90E8\u6E7E\u94F6\u884C" },
      { label: "\u9042\u5B81\u94F6\u884C" },
      { label: "\u6C5F\u82CF\u6C5F\u5357\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5E38\u719F\u5E02\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6D59\u5546\u94F6\u884C" },
      { label: "\u5BCC\u6EC7\u94F6\u884C" },
      { label: "\u5317\u4EAC\u519C\u5546\u884C" },
      { label: "\u5C27\u90FD\u519C\u4FE1\u793E" },
      { label: "\u6B66\u6C49\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5E7F\u4E1C\u534E\u5174\u94F6\u884C" },
      { label: "\u987A\u5FB7\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u91CD\u5E86\u519C\u5546\u884C" },
      { label: "\u798F\u5EFA\u6D77\u5CE1\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u67F3\u5DDE\u94F6\u884C" },
      { label: "\u676D\u5DDE\u5546\u4E1A\u94F6\u884C" },
      { label: "\u9655\u897F\u7701\u519C\u6751\u4FE1\u7528\u793E\u8054\u5408\u793E" },
      { label: "\u5174\u4E1A\u67F3\u94F6\u6751\u9547\u94F6\u884C" },
      { label: "\u7EA2\u661F\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5E7F\u53D1\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5170\u5DDE\u94F6\u884C" },
      { label: "\u6E56\u5357\u6C5D\u57CE\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u519C\u4FE1\u5C71\u897F" },
      { label: "\u6C5F\u897F\u5B81\u90FD\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u9655\u897F\u54B8\u9633\u79E6\u90FD\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5F20\u5317\u53BF\u519C\u6751\u4FE1\u7528\u8054\u5408\u793E" },
      { label: "\u6D59\u6C5F\u4E50\u6E05\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5B89\u5FBD\u5B81\u56FD\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6D59\u6C5F\u5BCC\u9633\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6797\u5DDE\u5E02\u519C\u6751\u4FE1\u7528\u793E" },
      { label: "\u5B89\u5FBD\u9A6C\u978D\u5C71\u519C\u6751\u5408\u4F5C\u94F6\u884C" },
      { label: "\u516D\u5B89\u519C\u5546\u94F6\u884C" },
      { label: "\u9EC4\u5C71\u592A\u5E73\u519C\u5546\u94F6\u884C" },
      { label: "\u6C60\u5DDE\u4E5D\u534E\u519C\u5546\u94F6\u884C" },
      { label: "\u4E1C\u5149\u53BF\u519C\u6751\u4FE1\u7528\u5408\u4F5C\u793E\u8054\u5408\u793E" },
      { label: "\u6CB3\u5317\u5143\u6C0F\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6CB3\u5317\u4E95\u9649\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6CB3\u5317\u7701\u5357\u548C\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u9752\u5C9B\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5B89\u4E18\u5E02\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6F4D\u574A\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6B66\u9091\u53BF\u519C\u6751\u4FE1\u7528\u8054\u793E" },
      { label: "\u957F\u6CBB\u6F5E\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u8499\u9634\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u4E1C\u8425\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6C38\u5E74\u53BF\u519C\u6751\u4FE1\u7528\u793E" },
      { label: "\u6D4E\u5357\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6CCA\u5934\u5E02\u519C\u6751\u4FE1\u7528\u5408\u4F5C\u793E\u8054\u5408\u793E" },
      { label: "\u77F3\u5634\u5C71\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6C5F\u82CF\u82CF\u5B81\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u4E0A\u9976\u94F6\u884C" },
      { label: "\u5B9C\u5BBE\u5E02\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u91D1\u534E\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5B81\u6CE2\u911E\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6E56\u5DDE\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u798F\u5EFA\u9F99\u6D77\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6C5F\u82CF\u5B9C\u5174\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5B81\u9655\u53BF\u519C\u6751\u4FE1\u7528\u5408\u4F5C\u793E\u8054\u5408\u793E" },
      { label: "\u6E56\u5DDE\u5434\u5174\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u534E\u745E\u94F6\u884C" },
      { label: "\u6CB3\u5317\u6CBD\u6E90\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5C71\u897F\u5E73\u5B9A\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6C5F\u9634\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6C5F\u82CF\u6CD7\u9633\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6CB3\u5317\u6DBF\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6C5F\u82CF\u4EEA\u5F81\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6C88\u9633\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u4E1C\u8425\u83B1\u5546\u6751\u9547\u94F6\u884C" },
      { label: "\u664B\u4E2D\u5E02\u592A\u8C37\u5174\u6CF0\u6751\u9547\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6C5F\u897F\u745E\u660C\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6C5F\u95E8\u878D\u548C\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u56DB\u5DDD\u5929\u5E9C\u94F6\u884C" },
      { label: "\u4E1C\u839E\u94F6\u884C" },
      { label: "\u4E0A\u6D77\u519C\u5546\u884C" },
      { label: "\u6CB3\u5317\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5DE5\u5546\u94F6\u884C" },
      { label: "\u5E7F\u5DDE\u94F6\u884C" },
      { label: "\u6E29\u5DDE\u94F6\u884C" },
      { label: "\u54C8\u5C14\u6EE8\u94F6\u884C" },
      { label: "\u957F\u6C99\u94F6\u884C" },
      { label: "\u6CC9\u5DDE\u94F6\u884C" },
      { label: "\u8425\u53E3\u94F6\u884C" },
      { label: "\u90AE\u50A8\u94F6\u884C" },
      { label: "\u4E1C\u4E9A\u94F6\u884C" },
      { label: "\u5357\u6D0B\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6052\u751F\u94F6\u884C" },
      { label: "\u664B\u57CE\u94F6\u884C" },
      { label: "\u96C6\u53CB\u94F6\u884C\u6709\u9650\u516C\u53F8" },
      { label: "\u521B\u5174\u94F6\u884C\u6709\u9650\u516C\u53F8" },
      { label: "\u661F\u5C55\u94F6\u884C(\u4E2D\u56FD)\u6709\u9650\u516C\u53F8" },
      { label: "\u6C38\u4EA8\u94F6\u884C(\u4E2D\u56FD)\u6709\u9650\u516C\u53F8" },
      { label: "\u6C38\u9686\u94F6\u884C" },
      { label: "\u82B1\u65D7\u94F6\u884C(\u4E2D\u56FD)\u6709\u9650\u516C\u53F8" },
      { label: "\u65E5\u672C\u4E09\u83F1\u4FE1\u7528\u5361\u516C\u53F8" },
      { label: "\u65E5\u672C\u4E09\u4E95\u4F4F\u53CB\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u53CB\u5229\u94F6\u884C(\u4E2D\u56FD)\u6709\u9650\u516C\u53F8" },
      { label: "\u6B66\u6C49\u519C\u5546\u94F6\u884C" },
      { label: "\u5E7F\u4E1C\u5357\u6D77\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5E7F\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u534E\u4E00\u94F6\u884C" },
      { label: "\u57CE\u5E02\u5546\u4E1A\u94F6\u884C" },
      { label: "\u53A6\u95E8\u56FD\u9645\u94F6\u884C" },
      { label: "\u4E4C\u6D77\u94F6\u884C" },
      { label: "\u6E23\u6253\u94F6\u884C\u4E2D\u56FD\u6709\u9650\u516C\u53F8" },
      { label: "\u65B0\u97E9\u94F6\u884C" },
      { label: "\u56DB\u5DDD\u7701\u519C\u6751\u4FE1\u7528\u793E\u8054\u5408\u793E" },
      { label: "\u8861\u6C34\u94F6\u884C" },
      { label: "\u798F\u5EFA\u7701\u519C\u6751\u4FE1\u7528\u793E\u8054\u5408\u793E" },
      { label: "\u5B89\u987A\u897F\u822A\u5357\u9A6C\u6751\u9547\u94F6\u884C" },
      { label: "\u5ECA\u574A\u94F6\u884C" },
      { label: "\u6CA7\u5DDE\u94F6\u884C" },
      { label: "\u90A2\u53F0\u94F6\u884C" },
      { label: "\u4FDD\u5B9A\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u5DE5\u5546\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u534E\u590F\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u5E7F\u897F\u519C\u6751\u4FE1\u7528\u793E\u8054\u5408\u793E" },
      { label: "\u629A\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u9AD8\u5DDE\u5E02\u519C\u6751\u4FE1\u7528\u5408\u4F5C\u8054\u793E" },
      { label: "\u5E7F\u4E1C\u9AD8\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u9655\u897F\u77F3\u6CC9\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5F20\u5BB6\u6E2F\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6606\u4ED1\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u6C47\u901A\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5E7F\u4E1C\u9633\u4E1C\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u6C11\u751F\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u76D8\u9526\u94F6\u884C" },
      { label: "\u4E2D\u56FD\u90AE\u653F\u50A8\u84C4\u94F6\u884C" },
      { label: "\u4E2D\u539F\u94F6\u884C" },
      { label: "\u6E56\u5357\u8D44\u5174\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6C5F\u897F\u82A6\u6EAA\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6D59\u6C5F\u7A20\u5DDE\u5546\u4E1A\u94F6\u884C" },
      { label: "\u978D\u5C71\u94F6\u884C" },
      { label: "\u6CB3\u5317\u9999\u6CB3\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u6C5F\u82CF\u6CF0\u5DDE\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u5E7F\u897F\u9633\u6714\u519C\u6751\u5408\u4F5C\u94F6\u884C" },
      { label: "\u6DC4\u535A\u6DC4\u5DDD\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u798F\u5EFA\u4E0A\u676D\u519C\u6751\u5546\u4E1A\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8   " },
      { label: "\u5E93\u5C14\u52D2\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u798F\u5B89\u5E02\u519C\u6751\u4FE1\u7528\u5408\u4F5C\u8054\u793E" },
      { label: "\u91CD\u5E86\u4E5D\u9F99\u5761\u6C11\u6CF0\u6751\u9547\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" },
      { label: "\u4EC1\u6000\u8499\u94F6\u6751\u9547\u94F6\u884C" },
      { label: "\u56DB\u5DDD\u7EF5\u7AF9\u519C\u6751\u5546\u4E1A\u94F6\u884C" },
      { label: "\u8FBD\u5B81\u632F\u5174\u94F6\u884C" },
      { label: "\u65B0\u7586\u94F6\u884C\u80A1\u4EFD\u6709\u9650\u516C\u53F8" }
    ];
  }
}
const YUNST_CONST = new YunstConst();

const HTTP_CONST = {
  responseCode: {
    success: 0,
    tokenError: 1002,
    tokenExpired: 2004
  }
};

class OrderRefundConst {
  constructor() {
    this.type = {
      normal: 1,
      abnormal: 2,
      apply: 3
    };
    this.status = {
      refunding: 1,
      fail: 2,
      success: 3
    };
    this.sourceType = {
      system: 0,
      admin: 1,
      merchant: 2,
      user: 3
    };
  }
  get statusMap() {
    return {
      [this.status.refunding]: "\u9000\u6B3E\u4E2D",
      [this.status.fail]: "\u9000\u6B3E\u5931\u8D25",
      [this.status.success]: "\u9000\u6B3E\u6210\u529F"
    };
  }
  get sourceTypeMap() {
    return {
      [this.sourceType.system]: "\u7CFB\u7EDF",
      [this.sourceType.admin]: "\u5E73\u53F0\u7AEF",
      [this.sourceType.merchant]: "\u5546\u6237\u7AEF",
      [this.sourceType.user]: "\u7528\u6237\u7AEF"
    };
  }
}
const ORDER_REFUND_CONST = new OrderRefundConst();

dayjs.extend(utc);
dayjs.extend(duration);

class CustomError extends Error {
  constructor(message, data) {
    super(message);
    this.data = {};
    this.name = "CustomError";
    this.data = data;
  }
}

const HTTP_ERROR_MAP = {
  general: "general",
  token: "token",
  http: "http",
  timeout: "timeout",
  unknown: "unknown"
};
const httpH5 = {
  responseSuccess(response, eventBus) {
    const { data } = response;
    const config = response.config;
    let result;
    if (+data.code === HTTP_CONST.responseCode.success) {
      result = data.data;
    } else {
      errorHandler(data, config.custom?.handleError, eventBus);
      result = Promise.reject(new CustomError(data.message || "business error", { data, config }));
    }
    return result;
  },
  responseFail(error, eventBus) {
    if (errorType(error) === HTTP_ERROR_MAP.timeout) {
      eventBus.emit(HTTP_ERROR_MAP.timeout, error);
    } else if (errorType(error) === HTTP_ERROR_MAP.http) {
      eventBus.emit(HTTP_ERROR_MAP.http, error);
    } else {
      eventBus.emit(HTTP_ERROR_MAP.unknown, error);
    }
    return Promise.reject(new CustomError(errorType(error), error));
    function errorType(error2) {
      let result = HTTP_ERROR_MAP.http;
      if (isTimeout(error2)) {
        result = HTTP_ERROR_MAP.timeout;
      }
      return result;
    }
    function isTimeout(error2) {
      return error2.message && error2.message.indexOf("timeout") > -1;
    }
  }
};
const httpUni = {
  responseSuccess(response, eventBus) {
    const { data, config } = response;
    let result;
    if (+data.code === HTTP_CONST.responseCode.success) {
      result = data.data;
    } else {
      errorHandler(data, config.custom?.handleError, eventBus);
      result = Promise.reject(new CustomError(data.message || "business error", { data, config }));
    }
    return result;
  },
  responseFail(error, eventBus) {
    if (errorType(error) === HTTP_ERROR_MAP.timeout) {
      eventBus.emit(HTTP_ERROR_MAP.timeout, error);
    } else if (errorType(error) === HTTP_ERROR_MAP.http) {
      error.statusCode = error.statusCode < 500 ? 404 : 500;
      eventBus.emit(HTTP_ERROR_MAP.http, error);
    } else {
      eventBus.emit(HTTP_ERROR_MAP.unknown, error);
    }
    return Promise.reject(new CustomError(errorType(error), error));
    function errorType(error2) {
      let result = HTTP_ERROR_MAP.http;
      if (isTimeout(error2)) {
        result = HTTP_ERROR_MAP.timeout;
      }
      return result;
    }
    function isTimeout(error2) {
      return error2.errMsg && error2.errMsg.indexOf("timeout") > -1;
    }
  }
};
function errorHandler(responseData, handleError, eventBus) {
  if (responseData.code === HTTP_CONST.responseCode.tokenError || responseData.code === HTTP_CONST.responseCode.tokenExpired) {
    eventBus.emit(HTTP_ERROR_MAP.token);
  } else if (!handleError) {
    eventBus.emit(HTTP_ERROR_MAP.general, responseData.message);
  }
}

const phoneRegExp = /^1[3456789]{10}$/;
function isPhone(phone) {
  return phoneRegExp.test(phone);
}
const positiveDecimalRegExp = /^([1-9]\d*(\.\d{1,2})?|0\.\d{1,2})$/;
function isPositiveDecimal(decimal) {
  return positiveDecimalRegExp.test(`${decimal}`);
}
const positiveNumberRegExp = /^[1-9]\d*$/;
function isPositiveNumber(number) {
  return positiveDecimalRegExp.test(`${number}`);
}

function minutesToString(minutes, unitObj) {
  return minutesToStringList(minutes, unitObj).join("");
}
function minutesToStringList(minutes, unitObj = {}) {
  const hourUnit = unitObj.hour || "\u5C0F\u65F6";
  const minuteUnit = unitObj.minute || "\u5206";
  const result = [];
  const hours = Math.floor(minutes / 60);
  const innerMinutes = minutes % 60;
  hours && result.push(hours + "", hourUnit);
  innerMinutes && result.push(innerMinutes + "", minuteUnit);
  return result;
}
function secondToString(second) {
  return secondToStringList(second).join("");
}
function secondToStringList(second) {
  const result = [];
  if (second) {
    const hours = Math.floor(second / 3600);
    hours && result.push(hours + "", "\u5C0F\u65F6");
    const minutesSecond = second % 3600;
    const minutes = Math.floor(minutesSecond / 60);
    minutes && result.push(minutes + "", "\u5206");
    second % 60 && result.push(second % 60 + "", "\u79D2");
  }
  return result;
}
function secondToColonString(second, fullTime) {
  const result = [];
  if (second) {
    const hours = Math.floor(second / 3600);
    (hours || fullTime) && result.push(padZero(hours));
    const minutesSecond = second % 3600;
    const minutes = Math.floor(minutesSecond / 60);
    (minutes || fullTime) && result.push(padZero(minutes));
    result.push(padZero(second % 60));
  }
  return result.join(":");
}
function padZero(number) {
  return number >= 10 ? `${number}` : `0${number}`;
}

function uniToast(message, option = {}) {
  uni.showToast({
    icon: "none",
    ...option,
    title: message
  });
}
function navigateToToast(message, url, option) {
  const duration = option?.duration || 1e3;
  uniToast(message, {
    mask: true,
    ...option,
    duration
  });
  setTimeout(() => {
    uni.navigateTo({
      url
    });
  }, duration);
}
function redirectToToast(message, url, option) {
  const duration = option?.duration || 1e3;
  uniToast(message, {
    mask: true,
    ...option,
    duration
  });
  setTimeout(() => {
    uni.redirectTo({
      url
    });
  }, duration);
}
function reLaunchToast(message, url, option) {
  const duration = option?.duration || 1e3;
  uniToast(message, {
    mask: true,
    ...option,
    duration
  });
  setTimeout(() => {
    uni.reLaunch({
      url
    });
  }, duration);
}
function switchTabToast(message, url, option) {
  const duration = option?.duration || 1e3;
  uniToast(message, {
    mask: true,
    ...option,
    duration
  });
  setTimeout(() => {
    uni.switchTab({
      url
    });
  }, duration);
}
function navigateBackToast(message, delta, option) {
  const duration = option?.duration || 1e3;
  uniToast(message, {
    mask: true,
    ...option,
    duration
  });
  setTimeout(() => {
    uni.navigateBack({
      delta
    });
  }, duration);
}

function centToYuan(cent, precision) {
  if (!cent)
    return 0;
  let result = `${cent / 100}`.replace(/(\.\d{2})\d*/, "$1");
  if (precision !== void 0) {
    result = (cent / 100).toFixed(2);
  }
  return parseFloat(result);
}
function yuanToCent(yuan) {
  if (!yuan)
    return 0;
  return new Big(yuan).mul(100).toNumber();
}
function metreToKilometer(metre) {
  if (!metre)
    return 0;
  return new Big(metre).div(1e3).toNumber();
}

const chargePlanTransform = {
  convertList(list) {
    return list.map((v) => ({
      ...v,
      chargeModelLabel: COMPANY_CHARGE_PLAN_CONST.chargeModelMap[v.chargeModel],
      companyName: v.companyName || "-",
      updateByName: v.updateByName || "-",
      updateTime: v.updateTime || "-",
      baseText: `${centToYuan(v.baseAmount)}\u5143/${v.baseMinutes}\u5206\u949F`,
      overtimeText: `${centToYuan(v.overtimeAmount)}\u5143/${v.overtimeMinutes}\u5206\u949F`
    }));
  },
  convertPreview(chargePlan) {
    const result = {};
    const isTime = getIsTime(chargePlan.chargeModel);
    const isPower = getIsPower(chargePlan.chargeModel);
    let minAmount;
    if (isTime && isPower) {
      minAmount = chargePlan.powerMinAmount;
    }
    result.otherPlanList = chargePlan.otherPlanList.map(transformItem).map((v) => ({ ...v, isOther: true }));
    if (!chargePlan.defaultPlanList.length) {
      chargePlan.defaultPlanList = chargePlan.otherPlanList.slice(0, 4);
    }
    const shortcutPlanList = chargePlan.defaultPlanList.concat({ isOther: true });
    if (chargePlan.fullStop === COMPANY_CHARGE_PLAN_CONST.fullStop.enable) {
      shortcutPlanList.unshift({ ...chargePlan.fullStopPlan, isFull: true });
    }
    result.shortcutPlanList = shortcutPlanList.map(transformItem);
    return result;
    function transformItem(item) {
      const result2 = {
        title: isTime ? minutesToStringList(item.planMinutes) : [centToYuan(item.planAmount) + "", "\u5143"],
        value: isTime ? centToYuan(item.planAmount) + "\u5143" : minutesToString(item.planMinutes),
        amount: item.planAmount,
        minutes: item.planMinutes,
        minAmount,
        label: "",
        isFull: item.isFull,
        isOther: item.isOther
      };
      result2.label = result2.title.join("") + "/" + result2.value;
      if (item.isFull) {
        result2.title = "\u5145\u6EE1\u81EA\u505C";
        result2.value = void 0;
      }
      if (item.isOther) {
        result2.title = "\u5176\u4ED6";
        result2.value = void 0;
      }
      return result2;
    }
  },
  convertDefaultCharge(list) {
    return list.reduce((prev, curr) => {
      prev[curr.chargeModel] = curr;
      return prev;
    }, {});
  },
  convertDefaultChargeToForm(obj) {
    return Object.keys(obj).reduce((prev, curr) => {
      const item = omit(obj[curr], "id");
      prev[curr] = {
        ...item,
        baseAmount: centToYuan(item.baseAmount),
        overtimeAmount: centToYuan(item.overtimeAmount),
        get powerChargeList() {
          if (getIsPower(item.chargeModel)) {
            return item.powerChargeList?.map((item2) => ({
              ...item2,
              baseAmount: centToYuan(item2.baseAmount),
              overtimeAmount: centToYuan(item2.overtimeAmount)
            })) || [];
          }
        },
        get candidatesType() {
          let result = item.candidatesType;
          if (!getIsTime(item.chargeModel)) {
            result = item.candidatesType.map((item2) => centToYuan(item2));
          }
          return result;
        }
      };
      return prev;
    }, {});
  },
  convertDefaultChargeValue(obj) {
    return Object.keys(obj).reduce((prev, curr) => {
      prev[curr] = chargePlanTransform.convertDetail(obj[curr]);
      return prev;
    }, {});
  },
  convertDetail(chargePlan) {
    return {
      ...chargePlan,
      isPower: getIsPower(chargePlan.chargeModel),
      isTime: getIsTime(chargePlan.chargeModel),
      get candidatesTypeUnit() {
        return this.isTime ? "\u5206\u949F" : "\u5143";
      },
      fullStopLabel: chargePlan.fullStop === COMPANY_CHARGE_PLAN_CONST.fullStop.enable ? "\u5F00\u542F" : "\u4E0D\u5F00\u542F",
      candidatesOrderLabel: chargePlan.candidatesOrder === COMPANY_CHARGE_PLAN_CONST.candidatesOrder.descend ? "\u4ECE\u5927\u5230\u5C0F" : "\u4ECE\u5C0F\u5230\u5927",
      get candidatesTypeLabel() {
        let result = chargePlan.candidatesType?.map((item) => item += this.candidatesTypeUnit).join("\u3001") || "-";
        if (!this.isTime) {
          result = chargePlan.candidatesType?.map((item) => centToYuan(item) + this.candidatesTypeUnit).join("\u3001") || "-";
        }
        return result;
      },
      chargeModelLabel: COMPANY_CHARGE_PLAN_CONST.chargeModelMap[chargePlan.chargeModel],
      baseAmount: centToYuan(chargePlan.baseAmount),
      overtimeAmount: centToYuan(chargePlan.overtimeAmount),
      get powerChargeList() {
        if (getIsPower(chargePlan.chargeModel)) {
          return chargePlan.powerChargeList?.map((item) => ({
            ...item,
            baseAmount: centToYuan(item.baseAmount),
            overtimeAmount: centToYuan(item.overtimeAmount)
          }));
        }
      },
      get candidatesType() {
        let result = chargePlan.candidatesType;
        if (!this.isTime) {
          result = chargePlan.candidatesType.map((item) => centToYuan(item));
        }
        return result;
      }
    };
  },
  formToData(form, id) {
    const result = {
      id,
      ...omit(form, ["powerChargeList", "baseMinutes", "baseAmount", "overtimeMinutes", "overtimeAmount"]),
      get candidatesType() {
        return form.candidatesType.filter((v) => !!v).map((item) => {
          let result2 = parseFloat(`${item}`);
          if (!getIsTime(form.chargeModel)) {
            result2 = yuanToCent(item);
          }
          return result2;
        });
      }
    };
    if (getIsPower(result.chargeModel)) {
      result.powerChargeParamList = form.powerChargeList.map((v) => ({
        ...v,
        baseAmount: yuanToCent(v.baseAmount),
        overtimeAmount: yuanToCent(v.overtimeAmount)
      }));
    } else {
      result.baseMinutes = parseInt(`${form.baseMinutes}`);
      result.baseAmount = yuanToCent(form.baseAmount);
      result.overtimeMinutes = parseInt(`${form.overtimeMinutes}`);
      result.overtimeAmount = yuanToCent(form.overtimeAmount);
    }
    return result;
  }
};
function getIsPower(chargeModel) {
  return [COMPANY_CHARGE_PLAN_CONST.chargeModel.powerTime, COMPANY_CHARGE_PLAN_CONST.chargeModel.powerAmount].includes(
    chargeModel
  );
}
function getIsTime(chargeModel) {
  return [COMPANY_CHARGE_PLAN_CONST.chargeModel.baseTime, COMPANY_CHARGE_PLAN_CONST.chargeModel.powerTime].includes(
    chargeModel
  );
}

const chargePlanOperationLogTransform = {
  convertList(list) {
    return list.map((item) => ({
      ...item,
      sourceTypeLabel: COMMON_CONST.sourceTypeMap[item.sourceType],
      operationTypeLabel: CHARGE_PLAN_OPERATION_LOG_CONST.operationTypeMap[item.operationType],
      chargePlan: chargePlanTransform.convertDetail(item.chargePlan)
    }));
  }
};

const orderTransform = {
  enrichOrder(order) {
    const canRefundAmount = centToYuan((order.orderAmount || 0) - (order.refundAmount || 0));
    const secureServiceStatusIsBought = order.secureServiceStatus === ORDER_CONST.secureServiceStatus.bought;
    const canRefund = (canRefundAmount > 0 || order.payType === ORDER_CONST.payType.mealCard && order.mealCardRefundStatus === COMPANY_MEAL_CARD_CONST.refundStatus.unRefunded || secureServiceStatusIsBought) && order.statusRefund !== ORDER_CONST.statusRefund.refunding && (order.status === ORDER_CONST.status.end || order.status === ORDER_CONST.status.refund);
    return {
      ...order,
      isMealCardOrder: ORDER_CONST.payType.mealCard === order.payType,
      payTypeLabel: ORDER_CONST.payTypeMap[order.payType],
      canRefundAmount,
      secureServiceStatusIsBought,
      canRefund,
      isCharging: order.status === ORDER_CONST.status.charging,
      mealCardRefundStatusLabel: ORDER_CONST.mealCardRefundStatusMap[order.mealCardRefundStatus],
      mealCardRefundStatusIsRefunded: ORDER_CONST.mealCardRefundStatus.refuned === order.mealCardRefundStatus,
      amountYuan: centToYuan(order.amount),
      payAmountYuan: centToYuan(order.payAmount),
      orderAmountYuan: centToYuan(order.orderAmount),
      refundAmountYuan: centToYuan(order.refundAmount)
    };
  }
};

const orderQueryTransform = {
  enrichList(list) {
    return list.map((v) => ({
      ...v,
      rmsCurrent: v.rmsCurrent + "A",
      activePower: v.activePower + "W",
      apparentPower: v.apparentPower + "W"
    }));
  }
};

const orderRefundTransform = {
  enrichList(list) {
    return list.map((v) => ({
      ...v,
      statusLabel: ORDER_REFUND_CONST.statusMap[v.status],
      sourceTypeLabel: ORDER_REFUND_CONST.sourceTypeMap[v.sourceType],
      amountYuan: centToYuan(v.amount || 0),
      description: v.description || "-"
    }));
  }
};

export { CHARGE_PLAN_OPERATION_LOG_CONST, COMMON_CONST, COMPANY_CHARGE_PLAN_CONST, COMPANY_CONST, COMPANY_MEAL_CARD_CONST, COMPANY_RECHARGE_CONST, CustomError, HTTP_CONST, HTTP_ERROR_MAP, ORDER_CONST, ORDER_REFUND_CONST, YUNST_CONST, centToYuan, chargePlanOperationLogTransform, chargePlanTransform, httpH5, httpUni, isPhone, isPositiveDecimal, isPositiveNumber, metreToKilometer, minutesToString, minutesToStringList, navigateBackToast, navigateToToast, orderQueryTransform, orderRefundTransform, orderTransform, phoneRegExp, positiveDecimalRegExp, positiveNumberRegExp, reLaunchToast, redirectToToast, secondToColonString, secondToString, secondToStringList, switchTabToast, uniToast, yuanToCent };
