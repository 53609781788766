<template>
  <le-select-remote-search
    v-model="myValue"
    :label="label"
    :options="myOptions"
    :placeholder="placeholder"
    :notnull="notnull"
    :large="large"
  />
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    label: {
      type: String,
    },
    /**
     * 是否必填
     */
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      myValue: "",
      myOptions: {
        url: this.$Config.apiUrl.getAllCompany,
        method: "post",
        params: {
          // isSignAgency: 1,
        },
        labelKey: "name",
        valueKey: "id",
        searchKey: "key",
      },
    };
  },
  watch: {
    value: {
      handler(val) {
        this.myValue = val;
      },
      immediate: true,
    },
    myValue: {
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("input", val);
        }
      },
    },
  },
  mounted() {
    this.myValue = this.value;
  },
  methods: {},
};
</script>

<style></style>
