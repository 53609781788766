<template>
  <el-scrollbar wrap-class="scrollbar-wrapper my-menu" style="height: 100%; overflow-x: hidden">
    <el-menu
      ref="AsideElMenu"
      :collapse="!sidebarOpened"
      unique-opened
      :show-timeout="100"
      :hide-timeout="100"
      :default-active="$route.path"
      mode="vertical"
      text-color="#303133"
      active-text-color="#007AFF"
      menu-trigger="click"
      :collapse-transition="true"
      router
    >
      <sidebar-item
        v-for="(item, index) in menu"
        :key="index"
        :index="index"
        :item="item"
        :base-path="item.path"
      />
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapState } from "vuex";

import SidebarItem from "./SidebarItem";

export default {
  components: {
    SidebarItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      menu: (state) => state.menu.menu,
      sidebarOpened: (state) => state.hamburger.sidebarOpened,
    }),
  },
};
</script>

<style lang="scss" scoped>
.scrollbar-wrapper {
  width: 232px;
  box-shadow: 0px 2px 10px 0px #ebeff5;
  overflow-x: hidden !important;

  & .is-horizontal {
    display: none;
  }

  /deep/ .el-scrollbar__bar {
    display: none !important;
  }
}
.my-menu {
  overflow-x: hidden !important;
}
</style>
