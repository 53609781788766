<template>
  <el-button icon="el-icon-upload2" class="s-btn-exp" @click="handlerExport">导出</el-button>
</template>

<script>
export default {
  props: {
    pageParam: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    download(url) {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          //测试链接console.log(a.href)
          //a.download = 'fileName.xlsx' // 下载文件的名字
          document.body.appendChild(a);
          a.click();
        });
    },
    handlerExport() {
      if (!this.pageParam) {
        this.$message.error("缺少导出参数");
        return;
      }
      let func = this.$Axios._post;
      if (this.pageParam.method.toLowerCase() == "get") {
        func = this.$Axios._get;
      }
      func({
        ...this.pageParam,
        params: {
          ...this.pageParam.params,
          pageNum: 1,
          pageSize: 10000,
          exportFlag: true,
        },
      }).then((res) => {
        window.location.href = res.result.excelUrl;
      });
    },
  },
};
</script>
