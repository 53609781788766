<script>
export default {
  name: "BaseEllipsis",

  props: {
    tooltip: {
      // 鼠标移入是否显示完整内容
      type: Boolean,
      default: true,
    },
    length: {
      type: Number,
      default: 10,
    },
  },

  data() {
    this.fullLength = 0; // 计算后的完整长度
    return {};
  },

  methods: {
    getFullLength(str) {
      return str.split("").reduce((pre, cur) => {
        const code = cur.charCodeAt(0);
        if (code >= 0 && code <= 128) {
          return pre + 1;
        }
        return pre + 2;
      }, 0);
    },

    cutStrByLength(str) {
      let length = 0;
      return str.split("").reduce((pre, cur) => {
        const code = cur.charCodeAt(0);
        if (code >= 0 && code <= 128) {
          length += 1;
        } else {
          length += 2;
        }
        if (length <= this.length) {
          return pre + cur;
        }
        return pre;
      }, "");
    },

    renderStr(str) {
      return <span>{this.cutStrByLength(str) + (this.fullLength > this.length ? "..." : "")}</span>;
    },

    renderToolTip(str) {
      return (
        <el-tooltip content={str} placement="top">
          {this.renderStr(str)}
        </el-tooltip>
      );
    },
  },

  render() {
    const str = this.$slots.default ? this.$slots.default.map((vNode) => vNode.text).join("") : "";
    this.fullLength = this.getFullLength(str);
    return this.tooltip && this.fullLength > this.length
      ? this.renderToolTip(str)
      : this.renderStr(str);
  },
};
</script>
