import { HTTP_ERROR_MAP, httpH5 } from "@charge/common";
import axios from "axios";
import { Message } from "element-ui";

import { AXIOS_DEFAULT_CONFIG } from "@/config/index";
import { requestSuccessFunc } from "@/config/interceptors/axios";
import store from "@/store";
import { eventBus } from "@/utils/event-bus";

const request = axios.create(AXIOS_DEFAULT_CONFIG);

// 注入请求拦截
request.interceptors.request.use(requestSuccessFunc);
// 注入响应拦截
request.interceptors.response.use(
  (response) => httpH5.responseSuccess(response, eventBus),
  (error) => httpH5.responseFail(error, eventBus)
);

eventBus.on(HTTP_ERROR_MAP.token, handleTokenError);
eventBus.on(HTTP_ERROR_MAP.general, handleGeneralError);
eventBus.on(HTTP_ERROR_MAP.http, handleHttpError);
eventBus.on(HTTP_ERROR_MAP.timeout, handleTimeoutError);
eventBus.on(HTTP_ERROR_MAP.unknown, handleUnknownError);

function handleTokenError() {
  store.dispatch("user/logoutNew");
}
function handleGeneralError(msg) {
  Message({ type: "error", message: msg });
}
function handleHttpError() {
  Message({ type: "error", message: "服务器错误" });
}
function handleTimeoutError() {
  Message({ type: "error", message: "请求超过" });
}
function handleUnknownError() {
  Message({ type: "error", message: "未知错误" });
}

export default request;
