<template>
  <div class="a-cursor-p" @click="handlerJumpDetail">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      required: false,
      type: String,
      default: "",
    },
    dQuery: {
      required: false,
      type: Object,
    },
    jumpType: {
      required: false,
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    handlerJumpDetail() {
      if (!this.url) return;
      if (this.jumpType && this.jumpType == "replace") {
        this.$route.meta.hash = "del";
        this.$router.replace({
          path: this.url,
          query: this.dQuery,
        });
      } else {
        this.$router.push({
          path: this.url,
          query: this.dQuery,
        });
      }
    },
  },
};
</script>
