<template>
  <el-dialog
    title="选择位置"
    append-to-body
    :visible.sync="dialogVisible"
    class="a-flex-cfsc address-dialog"
  >
    <iframe width="100%" style="height: 60vh; border: none" :src="map_src"></iframe>
  </el-dialog>
</template>

<script>
/**
 * 现勘选择
 */
export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      map_src:
        "https://mapapi.qq.com/web/mapComponents/locationPicker/v/index.html?search=1&type=1&key=6APBZ-D6PLX-UNL4V-TMFNI-SMHD5-WSBCB&referer=毛豆充",
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
    },
    dialogVisible: {
      handler(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {},
  created() {
    const that = this;
    window.addEventListener(
      "message",
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        const loc = event.data;
        if (loc && loc.module == "locationPicker") {
          //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'

          that.$Axios
            ._get({
              url: that.$Config.apiUrl.getLocationCode,
              params: {
                lat: loc.latlng.lat,
                lng: loc.latlng.lng,
              },
            })
            .then((res) => {
              that.$emit("choose", {
                provinceCode: res.result.data.provinceCode,
                cityCode: res.result.data.cityCode,
                areaCode: res.result.data.areaCode,
                province: res.result.data.province,
                city: res.result.data.city,
                area: res.result.data.area,
                lat: loc.latlng.lat,
                lng: loc.latlng.lng,
                fullAddress: loc.poiaddress,
                address: loc.poiaddress
                  .replace(res.result.data.province, "")
                  .replace(res.result.data.city, "")
                  .replace(res.result.data.area, ""),
              });
              that.dialogVisible = false;
              that.$emit("update:visible", that.dialogVisible);
            });
        }
      },
      false
    );
  },
  methods: {},
};
</script>

<style>
.address-dialog .el-dialog__body {
  padding: 0 20px 30px 20px !important;
}
</style>
