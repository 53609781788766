import { _get } from "@/axios/func";
import Config from "@/config";
import Utils from "@/utils";

const initInfo = {
  accountName: "",
  accountNumber: "",
  accountType: 0,
  adminId: 0,
  agencyLevel: 0,
  applyTime: 0,
  balance: 0,
  balanceLock: 0,
  balanceWaitConfirm: 0,
  bankBranch: "",
  bankId: null,
  bankName: "",
  brand: "",
  certImagePath: "",
  certNumber: "",
  certType: null,
  cityCode: "",
  createTime: 0,
  creditCode: "",
  districtCode: "",
  establishTime: null,
  id: 2,
  is3in1: 1,
  isDelete: 0,
  logoPath: "",
  name: "",
  newType: 0,
  orgImagePath: "",
  orgNumber: "",
  orgType: null,
  parentId: null,
  provinceCode: "",
  regFeedback: "",
  regState: 0,
  registrantId: 0,
  repIdNumber: "",
  repName: "",
  source: "",
  status: 0,
  type: 0,
  updateTime: 0,
  vault: "",
};
const state = {
  company: {
    ...initInfo,
  },
};

const mutations = {
  setCompany: (state, company) => {
    state.company = company;
  },
};

const actions = {
  /**
   * 重置商户vuex
   */
  resetCompany({ commit }) {
    commit("setCompany", initInfo);
  },

  /**
   * 获取当前商户默认商户
   */
  async getDefaultCompanyInfo({ commit }) {
    if (this.state.company.id > 0) {
      return this.state.company;
    }
    const arg = {
      url: Config.apiUrl.getDefaultCompanyInfo,
      showLoading: false,
    };
    const { result } = await _get(arg);
    if (result.code === 0) {
      const data = result.data;
      commit("setCompany", data);
      Utils.setCookie("companyId", data.id);
    } else {
      this.$message.error(result.msg || result.message);
    }
    return result.data;
  },

  /**
   * 获取当前商户默认商户
   */
  async getCompanyInfo({ commit }) {
    const arg = {
      url: Config.apiUrl.getDefaultCompanyInfo,
    };
    const { result } = await _get(arg);
    if (result.code === 0) {
      const data = result.data;
      commit("setCompany", data);
      Utils.setCookie("companyId", data.id);
    } else {
      this.$message.error(result.msg || result.message);
      this.Utils.removeToken();
    }

    return result.data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
