import Cookies from "js-cookie";

import config from "@/config/index";

const key = config.tokenKey;

const tokenCookie = {
  get() {
    let result;
    if (Cookies.get(key) !== "undefined") {
      result = Cookies.get(key);
    }
    return result;
  },
  set(token) {
    return Cookies.set(key, token);
  },
  remove() {
    return Cookies.remove(key);
  },
};

export default tokenCookie;
