import { HTTP_ERROR_MAP } from "@charge/common";
import mitt from "mitt";

export const eventBusKeys = {
  ...HTTP_ERROR_MAP,
  addUseSuggestPrice: "addUseSuggestPrice",
  editUseSuggestPrice: "editUseSuggestPrice",
  operatePriceSuccess: "operatePriceSuccess",
};

export const eventBus = mitt();
