<template>
  <div class="a-flex-rfsc a-ptb-12">
    <div v-show="label" class="s-search-label a-flex-rfec" style="width: 82px">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <div>{{ label }}</div>
    </div>
    <el-input
      class="a-ml-20 a-flex-1"
      :class="large ? 's-large-input' : 's-nomarl-input'"
      :value="value"
      v-bind="computedAttrs"
      @input="handlerChange"
    ></el-input>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      required: true,
      default: "",
    },
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedAttrs() {
      return {
        clearable: true,
        placeholder: "请输入",
        ...this.$attrs,
      };
    },
  },
  methods: {
    handlerChange(val) {
      let result = val;
      if (this.$attrs.maxlength) {
        result = val.slice(0, this.$attrs.maxlength);
      }
      this.$emit("input", result);
    },
  },
};
</script>
