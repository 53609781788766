<template>
  <div class="a-flex-rfsc a-ptb-12">
    <div v-show="label" class="s-search-label a-flex-rfec" style="width: 82px">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <div>{{ label }}</div>
    </div>
    <el-switch
      class="a-ml-20"
      :value="value"
      :active-value="acValue"
      :inactive-value="inacValue"
      :active-color="acColor"
      :inactive-color="inacColor"
      :active-text="actext"
      :inactive-text="inactext"
      @change="handlerChange"
    ></el-switch>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      required: true,
      default: true,
    },
    acValue: {
      default: 1,
    },
    inacValue: {
      default: 0,
    },
    acColor: {
      default: "#007AFF",
    },
    inacColor: {
      default: "#F56C6C",
    },
    actext: {
      default: "",
    },
    inactext: {
      default: "",
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handlerChange(e) {
      this.$emit("input", e);
      this.$emit("change", e);
    },
  },
};
</script>
