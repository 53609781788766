import { _get } from "@/axios/func";
import Config from "@/config";
const state = {
  menu: [],
  routeHis: [],
};
const mutations = {
  setMenu: (state, menu) => {
    state.menu = menu.map((v) => {
      if (v.path === "/price") {
        return {
          ...v,
          children: [
            ...v.children,
            // { path: "/price/price-add", name: "添加定价新", children: [] },
          ],
        };
      }
      if (v.path === "/franchisee") {
        return {
          ...v,
          children: [
            ...v.children,
            {
              id: 38,
              appId: "hichar.company.web.*",
              name: "合作加盟",
              path: "/customer/cooperate-list",
              orderNum: 1,
              children: [],
              parentId: 34,
              isDelete: 0,
              type: 1,
            },
          ],
        };
      }
      return v;
    });

    state.menu.push({
      id: 25,
      appId: "hichar.company.web.*",
      name: "通联管理",
      path: "/admin-vue3",
      icon: "https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/pc/menu-camera.png",
      iconActive: "https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/pc/menu-camera-active.png",
      orderNum: 17,
      parentId: 0,
      type: 1,
      children: [
        {
          id: 26,
          appId: "hichar.company.web.*",
          name: "通联管理",
          path: "/admin-vue3/yunst-manage",
          orderNum: 1,
          parentId: 25,
          isDelete: 0,
          type: 1,
        },
      ],
    });
  },

  addRouteHis: (state, route) => {
    state.routeHis.push(route);
  },
};

const actions = {
  setMenu({ commit }, data) {
    commit("setMenu", data);
  },
  addRouteHis({ commit }, data) {
    commit("addRouteHis", data);
  },

  async getMenu({ commit }) {
    const arg = {
      url: Config.apiUrl.getUserMenu,
      showLoading: false,
    };
    const { result } = await _get(arg);
    if (result.code === 0) {
      commit("setMenu", result.data);
    } else {
      this.$message.error(result.msg || result.message);
    }
    return result.data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
