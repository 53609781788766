<template>
  <div class="a-flex-rfsc a-ptb-12">
    <div v-show="label" class="s-search-label a-flex-rfec">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <span>{{ label }}</span>
    </div>
    <el-date-picker
      v-bind="$attrs"
      v-model="myValue"
      value-format="yyyy-MM-dd"
      class="date-range a-ml-20"
      :class="large ? 's-large-input' : 's-nomarl-input'"
      type="date"
      :placeholder="placeholder"
      @change="handlerOnPick"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      required: false,
      type: String,
      validator: (param) => {
        if (param && !param.match(/\d{4}-[01]{1}\d{1}-[0,1,2,3]{1}\d{1}/)) {
          return false;
        } else {
          return true;
        }
      },
    },

    placeholder: {
      required: false,
      type: String,
      default: "请选择日期",
    },
    /**
     * 是否必填
     */
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      myValue: this.value,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.myValue = val;
      },
    },
  },
  methods: {
    handlerOnPick() {
      this.$emit("input", this.myValue);
    },
  },
};
</script>

<style scoped>
.date-range {
  width: 240px;
}

.date-range .el-range-input {
  width: 90px;
}

.date-range-large {
  width: 376px;
}

.date-range-large .el-range-input {
  width: 108px;
}
</style>
