<template>
  <div class="a-flex-rfsc">
    <div class="a-flex-cfsfs">
      <el-cascader
        v-model="selectedOptions"
        class="areapick"
        :class="large ? 's-large-input' : 's-nomarl-input'"
        placeholder="请选择省市区"
        :options="options"
        clearable
        @change="handleChange"
      ></el-cascader>
    </div>
  </div>
</template>

<script>
/**
 * 省市区选择
 */
import { regionData } from "element-china-area-data";
export default {
  props: {
    province: {
      required: false,
      type: String,
    },
    city: {
      required: false,
      type: String,
    },
    area: {
      required: false,
      type: String,
    },
    placeholder: {
      type: String,
      default: "请输入详细地址",
    },
    label: {
      type: String,
    },
    /**
     * 是否必填
     */
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      options: regionData,
      selectedOptions: ["", "", ""],
      provinceChange: false,
      cityChange: false,
      areaChange: false,
    };
  },
  computed: {
    codeChange() {
      return {
        provinceChange: this.provinceChange,
        cityChange: this.cityChange,
        areaChange: this.areaChange,
      };
    },
  },
  watch: {
    province: {
      handler(val, oldval) {
        if (val != oldval || !oldval) {
          this.provinceChange = true;
        }
      },
    },
    city: {
      handler(val, oldval) {
        if (val != oldval || !oldval) {
          this.cityChange = true;
        }
      },
    },
    area: {
      handler(val, oldval) {
        if (val != oldval || !oldval) {
          this.areaChange = true;
        }
      },
    },
    codeChange: {
      handler() {
        if (this.provinceChange && this.cityChange && this.areaChange) {
          this.$set(this, "selectedOptions", [this.province, this.city, this.area]);
          this.provinceChange = false;
          this.cityChange = false;
          this.areaChange = false;
        }
      },
    },
  },
  mounted() {},
  methods: {
    handleChange(value) {
      this.$emit("update:province", value[0]);
      this.$emit("update:city", value[1]);
      this.$emit("update:area", value[2]);
    },
  },
};
</script>
