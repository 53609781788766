class CommonConst {
  constructor() {
    this.sourceType = {
      platform: 1,
      merchant: 2
    };
    this.sourceTypeMap = {
      [this.sourceType.platform]: "\u5E73\u53F0\u7AEF",
      [this.sourceType.merchant]: "\u5546\u6237\u7AEF"
    };
    this.protocolType = {
      tcp: 1,
      mqtt: 2
    };
    this.protocolTypeMap = {
      [this.protocolType.tcp]: "tcp",
      [this.protocolType.mqtt]: "mqtt"
    };
    this.protocolTypeList = [
      {
        label: "tcp",
        value: this.protocolType.tcp
      },
      {
        label: "mqtt",
        value: this.protocolType.mqtt
      }
    ];
  }
}
const COMMON_CONST = new CommonConst();

class CompanyChargePlanConst {
  constructor() {
    this.chargeModel = {
      baseTime: 1,
      baseAmount: 2,
      powerTime: 3,
      powerAmount: 4
    };
    this.chargeModelMap = {
      [this.chargeModel.baseTime]: "\u6309\u65F6\u8BA1\u8D39-\u65F6\u957F\u4E0B\u5355",
      [this.chargeModel.baseAmount]: "\u6309\u65F6\u8BA1\u8D39-\u91D1\u989D\u4E0B\u5355",
      [this.chargeModel.powerTime]: "\u529F\u7387\u8BA1\u8D39-\u65F6\u957F\u4E0B\u5355",
      [this.chargeModel.powerAmount]: "\u529F\u7387\u8BA1\u8D39-\u91D1\u989D\u4E0B\u5355"
    };
    this.chargeModelList = [
      { label: this.chargeModelMap[this.chargeModel.baseTime], value: this.chargeModel.baseTime },
      { label: this.chargeModelMap[this.chargeModel.baseAmount], value: this.chargeModel.baseAmount },
      { label: this.chargeModelMap[this.chargeModel.powerTime], value: this.chargeModel.powerTime },
      { label: this.chargeModelMap[this.chargeModel.powerAmount], value: this.chargeModel.powerAmount }
    ];
    this.fullStop = {
      enable: 1,
      disable: 2
    };
    this.fullStopList = [
      { label: "\u5F00\u542F", value: this.fullStop.enable },
      { label: "\u4E0D\u5F00\u542F", value: this.fullStop.disable }
    ];
    this.candidatesOrder = {
      descend: 1,
      ascend: 2
    };
    this.candidatesOrderList = [
      { label: "\u4ECE\u5927\u5230\u5C0F", value: this.candidatesOrder.descend },
      { label: "\u4ECE\u5C0F\u5230\u5927", value: this.candidatesOrder.ascend }
    ];
    this.baseMinutesOptions = [
      { label: 30, value: 30 },
      { label: 60, value: 60 },
      { label: 120, value: 120 },
      { label: 240, value: 240 },
      { label: 360, value: 360 },
      { label: 480, value: 480 },
      { label: 720, value: 720 },
      { label: 1440, value: 1440 }
    ];
    this.overtimeMinutesOptions = [
      { label: 15, value: 15 },
      { label: 30, value: 30 },
      { label: 45, value: 45 },
      { label: 60, value: 60 }
    ];
    this.maxChargeDurationOptions = [
      { label: 240, value: 240 },
      { label: 360, value: 360 },
      { label: 480, value: 480 },
      { label: 600, value: 600 },
      { label: 720, value: 720 },
      { label: 840, value: 840 },
      { label: 1200, value: 1200 },
      { label: 1440, value: 1440 }
    ];
  }
}
const COMPANY_CHARGE_PLAN_CONST = new CompanyChargePlanConst();

class ChargePlanOperationLogConst {
  constructor() {
    this.operationType = {
      add: 1,
      edit: 2,
      delete: 3
    };
    this.operationTypeMap = {
      [this.operationType.add]: "\u65B0\u589E\u5B9A\u4EF7",
      [this.operationType.edit]: "\u7F16\u8F91\u5B9A\u4EF7",
      [this.operationType.delete]: "\u5220\u9664\u5B9A\u4EF7"
    };
  }
}
const CHARGE_PLAN_OPERATION_LOG_CONST = new ChargePlanOperationLogConst();

class OrderConst {
  constructor() {
    this.status = {
      unpowered: 0,
      charging: 1,
      end: 2,
      cancel: 3,
      fail: 4,
      timeout: 5,
      refund: 6
    };
    this.statusRefund = {
      notApply: 0,
      refunding: 1,
      success: 4,
      fail: 5
    };
    this.payStatus = {
      unpaid: 0,
      paid: 1,
      partialRefund: 2,
      refunded: 3
    };
    this.rechargePayType = {
      balanceReharge: 0,
      charge: 2,
      onlinePhysicalCardRecharge: 4,
      physicalCardRecharge: 8
    };
    this.paymentChannel = {
      wechat: 1
    };
    this.payType = {
      balance: 1,
      wechat: 2,
      onlinePhysicalCard: 4,
      offlinePhysicalCard: 5,
      mealCard: 6
    };
    this.secureServiceStatus = {
      bought: 1,
      notBuy: 2,
      refunded: 3
    };
    this.mealCardRefundStatus = {
      refuned: 2,
      notRefund: 1
    };
    this.mealCardRefundStatusMap = {
      [this.mealCardRefundStatus.refuned]: "\u5DF2\u9000",
      [this.mealCardRefundStatus.notRefund]: "\u672A\u9000"
    };
  }
  get statusMap() {
    return {
      [this.status.unpowered]: "\u5F85\u5145\u7535",
      [this.status.charging]: "\u5145\u7535\u4E2D",
      [this.status.end]: "\u5DF2\u7ED3\u675F",
      [this.status.cancel]: "\u5DF2\u53D6\u6D88",
      [this.status.fail]: "\u4E0B\u5355\u5931\u8D25",
      [this.status.timeout]: "\u5DF2\u53D6\u6D88",
      [this.status.refund]: "\u5DF2\u9000\u6B3E"
    };
  }
  get paymentChannelMap() {
    return {
      [this.paymentChannel.wechat]: "\u5FAE\u4FE1\u652F\u4ED8"
    };
  }
  get payTypeMap() {
    return {
      [this.payType.balance]: "\u4F59\u989D\u652F\u4ED8",
      [this.payType.wechat]: "\u5FAE\u4FE1\u652F\u4ED8",
      [this.payType.onlinePhysicalCard]: "NFC\u5361\u652F\u4ED8",
      [this.payType.offlinePhysicalCard]: "NFC\u5361\u652F\u4ED8",
      [this.payType.mealCard]: "\u5957\u9910\u5361\u652F\u4ED8"
    };
  }
  get secureServiceStatusMap() {
    return {
      [this.secureServiceStatus.bought]: "\u5DF2\u8D2D\u4E70",
      [this.secureServiceStatus.notBuy]: "\u672A\u8D2D\u4E70",
      [this.secureServiceStatus.refunded]: "\u5DF2\u9000\u6B3E"
    };
  }
}
const ORDER_CONST = new OrderConst();

function throttle(func, delay = 500) {
  let timer = null;
  return function(...args) {
    if (!timer) {
      func.call(this, ...args);
      timer = setTimeout(function() {
        timer = null;
      }, delay);
    }
  };
}

export { COMPANY_CHARGE_PLAN_CONST as C, ORDER_CONST as O, COMMON_CONST as a, CHARGE_PLAN_OPERATION_LOG_CONST as b, throttle as t };
