/* eslint-disable simple-import-sort/imports */
import Vue from "vue";
import ElementUI from "element-ui";
import "@/assets/css/element-variables.scss";
import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import "@/assets/css/font-family.css";
import "@/assets/css/app.css";
import "@/assets/css/sub.css";
import "@/assets/icons";
import "@/assets/iconfont/iconfont.css";
import "@/assets/css/common.css";
import "windi.css";
import "@/components-new/index";
import "@/components/charts";
import "@/mixins/index";

import { _post, _get, _delete, _put, _patch } from "@/axios/func";
import Utils from "@/utils";
import Config from "@/config";

import App from "@/App";
import router from "@/router";
import store from "@/store";
import BaseVue from "@/views/common/base.vue";

import LeJumptoDetail from "./views/components/page/le-jumpto-detail.vue";
Vue.component("LeJumptoDetail", LeJumptoDetail);

import LeSelectLocalSearch from "./views/components/form/le-select-local-search.vue";
Vue.component("LeSelectLocalSearch", LeSelectLocalSearch);

import LeSelectRemoteSearch from "./views/components/form/le-select-remote-search.vue";
Vue.component("LeSelectRemoteSearch", LeSelectRemoteSearch);

import LeSelectNumRange from "./views/components/form/le-select-num-range.vue";
Vue.component("LeSelectNumRange", LeSelectNumRange);

import LeDateRange from "./views/components/form/le-date-range.vue";
Vue.component("LeDateRange", LeDateRange);

import LeDateChangeTime from "./views/components/form/le-date-changeTime.vue";
Vue.component("LeDateChangeTime", LeDateChangeTime);

import LeInputDistrict from "./views/components/form/le-input-district.vue";
Vue.component("LeInputDistrict", LeInputDistrict);

import LeInputDistrictChoose from "./views/components/form/le-input-district-choose.vue";
Vue.component("LeInputDistrictChoose", LeInputDistrictChoose);

import LeInputDistrictForm from "./views/components/form/le-input-district-form.vue";
Vue.component("LeInputDistrictForm", LeInputDistrictForm);

import LeInputDate from "./views/components/form/le-input-date.vue";
Vue.component("LeInputDate", LeInputDate);

import LeInput from "./views/components/form/le-input.vue";
Vue.component("LeInput", LeInput);

import LeInputSuffix from "./views/components/form/le-input-suffix.vue";
Vue.component("LeInputSuffix", LeInputSuffix);

import LeInputDisable from "./views/components/form/le-input-disable.vue";
Vue.component("LeInputDisable", LeInputDisable);

import LeInputNum from "./views/components/form/le-input-num.vue";
Vue.component("LeInputNum", LeInputNum);

import LeInputSlot from "./views/components/form/le-input-slot.vue";
Vue.component("LeInputSlot", LeInputSlot);

import LeInputIconSearch from "./views/components/form/le-input-icon-search.vue";
Vue.component("LeInputIconSearch", LeInputIconSearch);

import LeInputNumRange from "./views/components/form/le-input-num-range.vue";
Vue.component("LeInputNumRange", LeInputNumRange);

import LeInputPriceRange from "./views/components/form/le-input-price-range.vue";
Vue.component("LeInputPriceRange", LeInputPriceRange);

import LeSearchForm from "./views/components/form/le-search-form.vue";
Vue.component("LeSearchForm", LeSearchForm);

import LeSwitchBtn from "./views/components/form/le-switch-btn.vue";
Vue.component("LeSwitchBtn", LeSwitchBtn);

import LeInputAddress from "./views/components/form/le-input-address.vue";
Vue.component("LeInputAddress", LeInputAddress);

import LePagview from "./views/components/table/le-pagview.vue";
Vue.component("LePagview", LePagview);

import LeUploadFile from "./views/components/form/le-upload-file.vue";
Vue.component("LeUploadFile", LeUploadFile);

import LeInputAddressPoint from "./views/components/form/le-input-address-point.vue";
Vue.component("LeInputAddressPoint", LeInputAddressPoint);

import LeCompanyAllSelect from "./views/components/choose/le-company-all-select.vue";
Vue.component("LeCompanyAllSelect", LeCompanyAllSelect);

import LeCompanyUnderSelect from "./views/components/choose/le-company-under-select.vue";
Vue.component("LeCompanyUnderSelect", LeCompanyUnderSelect);

import LeCompanyNoAgencySelect from "./views/components/choose/le-company-noagency-select.vue";
Vue.component("LeCompanyNoagencySelect", LeCompanyNoAgencySelect);

import Breadcrumb from "./components/layout/components/bread-crumb.vue";
Vue.component("BreadCrumb", Breadcrumb);

import LeSurveyChoose from "./views/components/choose/le-survey-choose.vue";
Vue.component("LeSurveyChoose", LeSurveyChoose);
import LeProjectChoose from "./views/components/choose/le-project-choose.vue";
Vue.component("LeProjectChoose", LeProjectChoose);
import LePriceChoose from "./views/components/choose/le-price-choose.vue";
Vue.component("LePriceChoose", LePriceChoose);
import LeAddressChoose from "./views/components/choose/le-address-choose.vue";
Vue.component("LeAddressChoose", LeAddressChoose);

import LeSwitch from "./views/components/form/le-switch.vue";
Vue.component("LeSwitch", LeSwitch);

import LeEchartsLine from "./views/components/echarts/le-echarts-line.vue";
Vue.component("LeEchartsLine", LeEchartsLine);

import LePreviewImg from "./views/components/poppup/le-preview-img.vue";
Vue.component("LePreviewImg", LePreviewImg);

import LeUploadFileImgLY from "./views/components/form/le-upload-file-img-ly.vue";
Vue.component("LeUploadFileImgLy", LeUploadFileImgLY);

import LeUploadFileImg from "./views/components/form/le-upload-file-img.vue";
Vue.component("LeUploadFileImg", LeUploadFileImg);

import LeServicePrivacy from "./views/components/form/le-service-privacy.vue";
Vue.component("LeServicePrivacy", LeServicePrivacy);

import LeExportBtn from "./views/components/btn/le-export-btn.vue";
Vue.component("LeExportBtn", LeExportBtn);

import JdInputPrice from "./views/components/form/jd-input-price.vue";
Vue.component("JdInputPrice", JdInputPrice);

Vue.use(ElementUI, {
  size: "small",
});

Vue.prototype.$confirmModal = (title, content, options) => {
  const newOptions = {
    ...options,
  };
  if (options.asyncFn) {
    newOptions.beforeClose = async (action, instance, done) => {
      if (action === "confirm") {
        instance.confirmButtonLoading = true;
        try {
          await options.asyncFn();
        } finally {
          instance.confirmButtonLoading = false;
          done();
        }
      } else {
        instance.confirmButtonLoading = false;
        done();
      }
    };
  }
  return Vue.prototype.$confirm(title, content, newOptions);
};

/**
 * table表头固定sticky实现
 */
// 表格表头吸附效果
import tableSticky from "./utils/tableSticky";
import "./plugins/index";

Vue.use(tableSticky);

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.prototype.$Axios = {
  _post,
  _get,
  _delete,
  _put,
  _patch,
};
Vue.prototype.$Utils = Utils;
Vue.prototype.$Config = Config;
//按钮权限
Vue.prototype.$_has = function (value) {
  let isExist = false;
  const buttonpermsStr = sessionStorage.getItem("buttenpremissions");
  if (buttonpermsStr == undefined) {
    return false;
  }
  if (buttonpermsStr.indexOf(value) > -1) {
    isExist = true;
  }
  return isExist;
};

Vue.prototype.$eventBus = new Vue();

const ChildVue = Vue.extend(BaseVue);

new ChildVue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
