<template>
  <div class="a-flex-rfsfs a-ptb-12">
    <div v-show="label" class="s-search-label a-flex-rfec a-mt-8" style="width: 82px">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <div>{{ label }}</div>
    </div>

    <el-upload
      class="a-ml-20"
      :action="url"
      :headers="headers"
      :data="data"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :limit="1"
      :on-exceed="handleExceed"
      :file-list="fileList"
      name="file"
      :on-change="handleChange"
    >
      <el-button style="border: 1px solid #dadde0; border-radius: 4px" icon="el-icon-upload2">
        上传文件
      </el-button>
      <slot name="tip">
        <div class="el-upload__tip a-mt-8 a-c-999 a-fs-14">
          支持扩展名：.rar .zip .doc .docx .pdf .jpg...
        </div>
      </slot>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      required: false,
      default: "",
    },
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      url: this.$Config.axiosBaseUrl + this.$Config.apiUrl.uploadFile,
      headers: {
        token: "WVRNM05UYzFOR1JsWXpVeVlXVmtabVEzWkRNeVpEQXdPREppTmpZMU9XRT0=",
        appId: this.$Config.appId,
      },
      data: {
        key: "company/upload",
      },
      fileList: [],
      iswatch: true,
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val != "") {
          if (this.iswatch) {
            if (typeof val == "string") {
              const names = val.split("/");
              const showName = names[names.length - 1];
              this.fileList.push({
                name: showName,
              });
            }
            this.iswatch = false;
          }
        }
      },
    },
  },

  methods: {
    handleRemove() {},
    handlePreview() {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file) {
      const that = this;
      return this.$confirm(`确定移除 ${file.name}？`, {
        callback: (action) => {
          if (action == "confirm") {
            that.$emit("input", "");
            that.fileList.splice(0, 1);
          }
        },
      });
    },
    handleChange(file) {
      this.iswatch = false;
      if (file.response) {
        this.$emit("input", file.response.data);
      }
    },
  },
};
</script>
