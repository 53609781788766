import { render, staticRenderFns } from "./TagsView.vue?vue&type=template&id=117af51a&scoped=true"
import script from "./TagsView.vue?vue&type=script&lang=js"
export * from "./TagsView.vue?vue&type=script&lang=js"
import style0 from "./TagsView.vue?vue&type=style&index=0&id=117af51a&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "117af51a",
  null
  
)

export default component.exports