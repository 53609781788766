<template>
  <el-dialog title="选择现勘" :visible.sync="dialogVisible">
    <div>
      <le-input-icon-search
        v-model="areaSearchKey"
        placeholder="请输入小区名称"
      ></le-input-icon-search>
    </div>
    <div class="a-flex-rfsfs a-mt-24">
      <div style="height: 405px; width: 160px; overflow-y: scroll">
        <el-table :show-header="false" :data="areaList">
          <el-table-column width="144">
            <template slot-scope="{ row }">
              <div class="a-flex-rsbc" @click="handlerChooseArea(row)">
                <span style="width: 120px">{{ row.areaName }}</span>
                <img
                  v-if="chooseAreaId == row.id"
                  src="../../../assets/icon/option-right-blue.png"
                  style="width: 6px; height: 8px"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="a-flex-cfsfe a-flex-1 a-ml-24" style="height: 405px">
        <div class="a-flex-rfec">
          <div class="a-flex-ccfe">
            <span class="a-c-999 a-fs-12">地址</span>
            <span class="a-c-333 a-fs-12 a-mt-10">{{ chooseArea.areaAddress }}</span>
          </div>
          <div class="a-flex-ccfe a-ml-48" style="width: 96px">
            <span class="a-c-999 a-fs-12">现勘日期</span>
            <span class="a-c-333 a-fs-12 a-mt-10">{{ chooseArea.createTimeText }}</span>
          </div>
        </div>

        <el-table :data="stationList" class="a-mt-16" height="365">
          <el-table-column property="positionName" label="站点名称"></el-table-column>
          <el-table-column label="附近照片">
            <template slot-scope="{ row }">
              <template v-if="row.nearbyPointImage && JSON.parse(row.nearbyPointImage).length > 0">
                <el-image
                  :src="JSON.parse(row.nearbyPointImage)[0]"
                  style="width: 120px; height: 80px"
                  fit="cover"
                />
              </template>
            </template>
          </el-table-column>
          <el-table-column property="pointNum" label="预估设备数量"></el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="{ row }">
              <el-button type="info" plain @click="handlerChooseStation(row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
/**
 * 现勘选择
 */
export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
      default: false,
    },
    areaId: {
      required: false,
    },
    areaName: {
      required: false,
    },
    stationId: {
      required: false,
    },
    stationName: {
      required: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      areaList: [],
      stationList: [],
      chooseAreaId: "",
      chooseArea: "",
      areaSearchKey: "",
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
    },
    dialogVisible: {
      handler(val, oldval) {
        if (val != oldval) {
          this.$emit("update:visible", val);
        }
      },
    },
    areaSearchKey: {
      handler() {
        this.getAreaList();
      },
    },
  },
  mounted() {
    this.getAreaList();
  },
  methods: {
    handleChange(value) {
      this.$emit("handlerAreaChange", value);
    },
    handlerChooseArea(item) {
      this.chooseAreaId = item.id;
      this.chooseArea = item;
      this.getStationList();
    },
    handlerChooseStation(item) {
      this.$emit("update:areaId", this.chooseArea.id);
      this.$emit("update:areaName", this.chooseArea.areaName);
      this.$emit("update:stationId", item.id);
      this.$emit("update:stationName", item.positionName);
      this.dialogVisible = false;
    },
    getAreaList() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getSurveyArea,
          params: {
            key: this.areaSearchKey,
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          this.areaList = res.result.data.list;
          if (this.areaList.length > 0) {
            this.chooseAreaId = this.areaList[0].id;
            this.chooseArea = this.areaList[0];
            this.getStationList();
          } else {
            this.stationList = [];
            this.chooseArea = {};
          }
        });
    },
    getStationList() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getSurveyStation,
          params: {
            surveyAreaId: this.chooseAreaId,
          },
        })
        .then((res) => {
          this.stationList = res.result.data;
        });
    },
  },
};
</script>
