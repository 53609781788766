<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view v-if="show" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style>
.app-main {
  /*50 = navbar  */
  /*34 = tagsView */
  height: calc(100vh - 104px);
  position: relative;
  overflow: hidden;
  background: #f5f7fa;
  width: 100%;
}

.app-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 32px;
  padding-left: 32px;
  font-family: "PingFang-Regular";
}

.app-body {
  height: calc(100vh - 84px - 40px);
}

.el-tabs__active-bar {
  background-color: #007aff;
}

.el-tabs__item {
  color: #606366;
  font-size: 12px;
}

.el-tabs__item:hover {
  color: #007aff;
  font-size: 14px;
}

.el-tabs__item.is-active {
  color: #303133;
  font-size: 14px;
}

/* 	.el-input__inner{
		width: 240px;
	} */
</style>
