<template>
  <div class="a-flex-rfsc a-ptb-12">
    <div v-show="label" class="s-search-label a-flex-rfec">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <span>{{ label }}</span>
    </div>
    <el-select
      ref="myselect"
      class="a-ml-20 a-flex-1"
      :class="large ? 's-large-input' : 's-nomarl-input'"
      :value="value"
      filterable
      clearable
      :multiple="multiple"
      :collapse-tags="collapse"
      remote
      :placeholder="placeholder"
      :disabled="readonly"
      :remote-method="remoteMethod"
      @change="handlerChange"
      @clear="remoteMethod('')"
    >
      <el-option
        v-for="item in myOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    label: {
      type: String,
    },
    /**
     * 是否必填
     */
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * 是否为较长的输入框
     */
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    collapse: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
      validator: (param) => {
        if (!param.url) {
          return false;
        }
        if (!param.method) {
          return false;
        }
        if (!param.params) {
          return false;
        }
        if (!param.labelKey) {
          return false;
        }
        if (!param.valueKey) {
          return false;
        }
        if (!param.searchKey) {
          return false;
        }
        return true;
      },
    },
    // 是否通联认证商户
    yunst: {
      type: Boolean,
      default: false,
    },
  },
  // watch: {
  // 	value: {
  // 		// 子级下拉框的监听对象
  // 		deep: true,
  // 		handler: function(val) {
  // 			this.remoteMethod();
  // 		},
  // 		immediate: true
  // 	},
  // },
  data() {
    return {
      myOptions: [],
      selectLabel: "",
      myValue: "",
      scrollview: null,
    };
  },
  mounted() {
    this.remoteMethod();
    // 获取指定元素
    this.scrollview = document.getElementsByClassName("el-main")
      ? document.getElementsByClassName("el-main")[0]
      : null;
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    if (this.scrollview && this.$refs.myselect) {
      this.scrollview.addEventListener(
        "scroll",
        () => {
          if (this.$refs.myselect.blur) this.$refs.myselect.blur();
        },
        true
      );
    }
  },
  beforeDestroy() {
    if (this.scrollview) this.scrollview.removeEventListener("scroll", () => {}, true);
  },
  methods: {
    handlerChange(e) {
      this.$emit("input", e);
    },
    remoteMethod(key) {
      let func = this.$Axios._post;
      if (this.options.method.toLowerCase() == "get") {
        func = this.$Axios._get;
      }
      const params = {
        ...this.options.params,
        pageNum: 1,
        pageSize: 100000,
      };
      params[this.options.searchKey] = key;

      func({
        ...this.options,
        params: params,
      }).then((res) => {
        if (this.yunst) {
          res.result.data.list = res.result.data.list.filter((item) => {
            return item.state === 4;
          });
        }
        this.myOptions = res.result.data.list.map((item) => {
          if (this.value == item[this.options.valueKey]) {
            this.selectLabel = item[this.options.labelKey];
          }
          return {
            label: item[this.options.labelKey],
            value: item[this.options.valueKey],
          };
        });
      });
    },
  },
};
</script>
