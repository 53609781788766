export default {
  //用户信息
  getUserInfo: "api/user/user/userInfo", //获取用户信息
  getCompanyInfoNoCache: "api/company/company/getCompanyInfoNoCache", //获取公司信息

  // 充值管理
  getRechargeList: "api/company/recharge/manage/listByPage", // 分页查询
  getRechargeTotalAmount: "api/company/recharge/manage/countAmountOfListPage", // 支付金额求和

  //站点管理
  getStationInfoList: "api/company/station/getStationInfoList", //获取站点管理列表
  getStationDetail: "api/company/station/getStationDetail", //获取站点信息
  bindPlan: "api/company/station/bindPlan", //绑定站点定价
  setStationElectric: "api/company/station/setStationElectric", //设置站点参数
  changeStationName: "api/company/station/changeStationName", //更改站点名称
  changeStationCompanyId: "api/company/station/changeStationCompanyId", //修改站点所属商户
  changeStationOpCompanyId: "api/company/station/changeStationOpCompanyId", //修改站点运营方商户
  cancelCreateStation: "api/company/station/cancelCreateStation", //取消建站

  //定价
  getPlanDetail: "api/company/plan/getPlanDetail", //获取定价明细
  getPlanPower: "api/company/plan/getPlanPower", //获取定价明细
  getPlanUserStationSize: "api/company/plan/getPlanUserStationSize", //获取定价相关站点数
  delPlan: "api/company/plan/delPlan", //删除定价
  createPlan: "api/company/plan/createPlan", //新建定价
  editPlan: "api/company/plan/editPlan", //修改定价

  getUnderCompany: "api/company/company/getUnderCompany", //获取隶属于本商户的商户列表,用户商户选择
  getAllCompany: "api/company/company/getAllCompany", //获取所有商户列表,用于商户选择
  getNoAgencyCompany: "api/company/company/getNoAgencyCompany", //获取没有签署加盟商协议的商户,用于商户选择
  getIncomeStatInfoList: "api/company/incomeDistri/getIncomeStatInfoList", //项目管理-获取方案视图统计列表
  getIncomeStatByStationInfoList: "api/company/incomeDistri/getIncomeStatByStationInfoList", //获取站点视图统计列表
  uploadFile: "/api/company/file/aliOssFileUpload", //上传文件
  getSurveyArea: "api/company/survey/getSurveyArea", //获取现勘小区信息
  getSurveyStation: "api/company/survey/getSurveyStation", //获取现勘站点信息

  getDistriSelectList: "api/company/incomeDistri/getDistriSelectList", //获取收益划分方案选择列表
  getPlanList: "api/company/plan/getPlanList", //获取定价列表
  createStation: "api/company/station/createStation", //申请站点
  getLocationCode: "api/company/map/getLocationCode", //地址逆解析

  //项目管理
  createIncome: "api/company/incomeDistri/createIncome", //创建收益划分方案
  getCompanyMaxRate: "api/company/agency/getCompanyMaxRate", //获取最大可分配收益比例
  getIncomeDetailInfo: "api/company/incomeDistri/getIncomeDetailInfo", //获取收益方案详细信息
  getStatOrderAmoutList: "api/company/stat-pc/getStatOrderAmountList", //获取收益趋势
  getStatOrderAmountTotal: "api/company/stat-pc/getStatOrderAmountTotal", //获取收益趋势总计
  getStatEnergyTotal: "api/company/stat-pc/getStatEnergyTotal", //获取电量统计总计
  getStatEnergyList: "api/company/stat-pc/getStatEnergyList", //获取电量统计
  getIncomeChangeRecord: "api/company/incomeDistri/getIncomeChangeRecord", //获取变更记录
  getIncomeTimeLine: "api/company/incomeDistri/getIncomeTimeLine", //获取收益方案操作历史
  gitEditIncomeDetailInfo: "api/company/incomeDistri/getEditIncomeDetailInfo", //获取编辑页面收益方案详细信息
  changeIncome: "api/company/incomeDistri/changeIncome", //提交变更方案
  notAgreeIncome: "api/company/incomeDistri/notAgreeIncome", //对方案提出异议
  cancelIncome: "api/company/incomeDistri/cancelIncome", //取消方案
  confirmIncome: "api/company/incomeDistri/confirmIncome", //确认方案
  delIncome: "api/company/incomeDistri/delIncome", //删除项目

  //订单管理
  getOrderInfoById: "api/order/orderQuery/getOrderInfoById", //订单详情
  getOrderChargePlanInfo: "api/company/order/getOrderChargePlanInfo", //查询订单使用的定价计划
  getOrderPower: "api/order/orderQuery/getOrderPower", //获取订单的功率记录
  refundOrder: "api/order/order/refundOrder", //订单开始退款
  getOrderList: "api/company/order/getOrderList", //订单列表
  cancelByAdministrator: "api/order/order/cancelByAdministrator", //管理员结束订单

  //待办事项
  getWithDoneList: "api/company/notify/getWithDoneList", //获取待办事项列表
  getWithDoneDealNum: "api/company/notify/getWithDoneDealNum", //获取待办事项待处理总数

  //消息
  readNotify: "api/company/notify/pc_readNotify", //标记消息已读
  getNotifyList: "api/company/notify/getNotifyList", //获取消息列表
  getNotifyPreview: "api/company/notify/getNotifyPreview", //获取我的消息预览信息
  getMyNotReadMessageCount: "api/company/notify/getMyNotReadMessageCount", //获取我的未读消息数
  getNotifyNum: "api/company/notify/getNotifyNum", //获取消息总数

  //充电卡管理
  chargeCardList: "api/company/pc_card/cardList", //充电卡列表（基础信息）
  onLineCardList: "api/company/pc_card/onLineCardList", //在线卡列表
  offLineCardList: "api/company/pc_card/offLineCardList", //离线卡列表
  addOffLineCard: "api/company/pc_card/add_offLineCard", //新增离线卡
  unbindUserCard: "api/company/pc_card/unbind_userCard", //在线卡解绑
  chargeAddCard: "api/company/pc_card/add_card", //新增卡(基础)
  chargeCardBatchImport: "api/company/pc_card/card_batchImport", //实体基础卡批量导入
  offLineCardBatchImport: "api/company/pc_card/offLineCard_batchImport",
  cardStatusUp: "api/company/pc_card/cardStatusUp",
  offLineCardStatusUp: "api/company/pc_card/offLineCardStatusUp",

  //客户管理
  getCompanyManagerList: "api/company/company/getCompanyManagerList", //获取客户列表
  authCompany: "api/company/company/authCompany", //商户入驻审核
  getCompanyManagerInfo: "api/company/company/getCompanyManagerInfo", //获取客户管理详细信息
  adminOcrForCompany: "api/user/user/adminOcrForCompany", //注册商户实名认证
  substituteRegisterPc: "api/company/register/substituteRegister-pc", //代注册
  getCooperationList: "api/user/userCooperation/getCooperationList", //合作加盟列表

  //首页
  slotUsedInfo: "api/company/pc_appStat/slotUsedInfo", //获取使用情况(插槽)
  payType: "api/company/pc_appStat/payType", //支付方式
  orderNumber: "api/company/pc_appStat/orderNumber", //订单量
  deviceUsedInfo: "api/company/pc_appStat/deviceUsedInfo", //获取使用情况(设备)
  deviceSlot: "api/company/pc_appStat/deviceSlot", //设备插槽
  companyTurnoverRank: "api/company/pc_appStat/companyTurnoverRank", //本月商户营业额排行
  chargingDeviceInstall: "api/company/pc_appStat/chargingDeviceInstall", //各地区充电桩安装/拆除情况
  businessVolume: "api/company/pc_appStat/businessVolume", //获取营业额
  searchStation: "api/company/pc_appStat/searchStation", //搜索充电桩点位

  //采购管理
  goodsOrderlist: "api/company/goodsOrder/list", //商品订单列表
  checkOrder: "api/company/goodsOrder/checkOrder", //采购订单审核

  getgoodsOrderInfo: "api/company/goodsOrder/info", //商品订单详情

  //用户管理
  getUserManagerList: "api/user/user-manager/getUserManagerList", //获取用户管理列表
  getUserManagerDetail: "api/user/user-manager/getUserManagerDetail", //获取用户管理详情
  getUserChargeBalanceInfoList: "api/user/user-manager/getUserChargeBalanceInfoList", //获取用户充值记录列表
  getUserConsumeList: "api/user/user-manager/getUserConsumeList", //获取用户消费记录
  getTransfersList: "api/user/balance/getTransfersList", //获取用户提现记录
  userCardListPC: "api/user/userCard/userCardList-pc", //获取用户实体卡列表PC管理端
  getAdviceList: "api/user/userCooperation/feedbackListPage", //用户反馈
  getFeedbackTypeConfig: "api/user/userCooperation/getFeedbackTypeConfig", //反馈配置

  //登录
  mobileLogin: "api/user/user/mobile-login", //PC端手机号登录
  getDefaultCompanyInfo: "api/company/company/getDefaultCompanyInfo", //获取当前用户默认商户

  // 设备管理
  getDeviceType: "api/company/device/getDeviceTypeList", // 获取设备类型
  getDeviceList: "api/company/device/getDeviceList", // 获取设备类型
  editDeviceType: "api/company/device/editDeviceType", // 获取设备类型
  getDeviceTypeDetail: "api/company/device/getDeviceTypeDetail", // 获取设备类型详情
  getDeviceBaseInfo: "api/company/device/getDeviceBaseInfo", // 获取设备详情
  queryDeviceInfo: "api/device/device/queryDeviceInfo", // 获取设备编辑详情
  getDeviceRealTimeInfo: "api/device/device/getDeviceStatus", // 获取设备实时信息
  getDeviceRealTimeInfoManual: "api/device/iot/sense", // 获取设备实时信息(主动检测)
  getDeviceOptionTimeline: "api/company/device/getDeviceOptionTimeline", // 获取设备绑定解绑记录
  getDeviceOnlineRecord: "api/device/device/getDeviceOnlineRecord", // 获取设备上线离线记录
  batchUnBindDevice: "api/device/device/batchUnBindDevice", // 批量解绑设备
  batchSetDeviceStopUse: "api/device/device/batchSetDeviceStopUse", // 批量停用设备
  batchSetDeviceCanUse: "api/device/device/batchSetDeviceCanUse", // 批量启用设备
  batchReboot: "api/device/iot/batch-reboot", // 批量重启设备
  getDeviceEvent: "api/device/device/getDeviceEvent", // 获取设备事件记录
  getDeviceLog: "api/device/device/getDeviceLog", // 获取设备流水日志
  addDevice: "api/device/device/addDevice", // 添加设备
  editDevice: "api/device/device/editDevice", // 编辑设备
  getJdDeviceDefaultParam: "api/device/param/getJdDeviceDefaultParam", // 获取'二路'类型设备默认参数 (1,2类型)
  getJdDeviceParam: "api/device/param/getJdDeviceParam", // 获取'二路'类型设备参数 (1,2类型)
  getYd10DeviceDefaultParam: "api/device/param/getYd10DeviceDefaultParam", // 获取'十路'类型设备默认参数 (5类型)
  getYd10DeviceParam: "api/device/param/getYd10DeviceParam", // 获取'十路'类型设备参数 (5类型)
  getYd12DeviceDefaultParam: "api/device/param/getYd12DeviceDefaultParam", // 获取'十二路'类型设备默认参数 (3,4类型)
  getYd12DeviceParam: "api/device/param/getYd12DeviceParam", // 获取'十二路'类型设备参数 (3,4类型)
  batchParams: "api/device/param/batch-param", // 批量下发参数
  batchImportDev: "api/device/device/batchImport", // 批量导入
  bindDevice: "api/device/device/bindDevice", //站点绑定设备

  // 故障管理
  getDeviceFaultList: "api/device/deviceFault/list", // 获取故障列表
  getDeviceFaultDetail: "/api/device/deviceFault/getDetail", // 获取故障详情
  manualClearFault: "api/device/deviceFault/manualClearFault", // 手动处理故障

  // 经营分析
  getStatProvinceList: "api/company/stat-pc/getStatProvinceList", // 获取经营分析列表（按省份）
  getStatIncomeList: "api/company/stat-pc/getStatIncomeList", // 获取经营分析列表（按项目）
  getStatCompanyList: "api/company/stat-pc/getStatCompanyList", // 获取经营分析列表（按商户）
  getStatCityList: "api/company/stat-pc/getStatCityList", // 获取经营分析列表（按城市）
  getStatStationList: "api/company/stat-pc/getStatStationList", // 获取经营分析列表（按站点）
  getStatOrderAmountList: "api/company/stat-pc/getStatOrderAmountList", // 获取收益趋势图表
  getStatDeviceAmountDistriList: "api/company/stat-pc/getStatDeviceAmountDistriList", // 获取单桩收益分布表格
  getStatDeviceAmountDistriDetailList: "api/company/stat-pc/getStatDeviceAmountDistriDetailList", // 获取单桩收益站点分布图表
  getStatOrderTimeDistriList: "api/company/stat-pc/getStatOrderTimeDistriList", // 获取设备充电时长分布图表
  getStatOfflineDeviceList: "api/company/stat-pc/getStatOfflineDeviceList", //  获取设备离线天数分布
  getStatOfflineDeviceList2: "api/company/stat-pc/getStatOfflineDeviceList2", // 获取设备情况
  getStatDeviceInstallNumList: "api/company/stat-pc/getStatDeviceInstallNumList", // 获取设备安装趋势图表
  getStationAnalysisDetail: "api/company/station/getStationAnalysisDetail", // 获取站点电费统计

  // 收益账单
  getBalanceDateDetail: "api/company/bill/getBalanceDateDetail-pc", // 获取收益账单详情
  getBalanceDateList: "api/company/bill/getBalanceDateList-pc", // 获取收益账单列表
  getStationListByBill: "api/company/bill/getStationListByBill", // 获取收益账单站点列表

  // 现勘管理
  getSurveyAreaDetail: "api/company/survey/getSurveyAreaDetail", // 获取现勘详情
  checkSurveyStation: "api/company/survey/checkSurveyStation", // 现勘站点审核
  getSurveyStationDetail: "api/company/survey/getSurveyStationDetail", // 现勘站点详情

  // 商户中心
  getMineStat: "api/company/stat/getMineStat", // 获取商户累计用户、本月新增用户、累计站点、本月新增站点
  getUnConfirmAmount: "api/company/company/getUnConfirmAmount", // 获取商户未入账金额
  getBalanceChangeList: "api/company/balance/getBalanceChangeList", // 获取商户收入明细
  withdrawSuccessList: "api/company/withdraw/withdrawSuccessInfo", // 获取商户提现成功明细
  bindBankAccount: "api/company/companybank/bindBankAccount", // 绑定银行卡
  unbindBankAccount: "api/company/companybank/unbindBankAccount", // 解绑银行卡
  getBankAccountList: "api/company/companybank/getBankAccountList", // 获取银行账户列表
  getTodayWithdrawAmount: "api/company/withdraw/getTodayWithdrawAmount", // 获取今日已申请提现金额
  applyWithdraw: "api/company/withdraw/applyWithdraw", // 提交提现申请
  getWithdrawList: "api/company/withdraw/getWithdrawList", // 提现申请记录
  chooseCompany: "api/company/company/chooseCompany", // 提现申请记录
  getUserAccountStatus: "api/pay/company-tl/getCompanyAccountStatus", // 通联交易权限 状态获取
  getCompanyAccount: "api/pay/company-tl/pc/getCompanyAccount", // 通联交易权限信息

  // 开户行
  getDictBankList: "api/company/dictPc/getDictBank", // 获取开户行
  getDictBankProvince: "api/company/dictPc/getProvince", // 获取开户行所在省份
  getDictBankBranchList: "api/company/dictPc/getBranchList", // 获取开户行支行
  getDictBankArea: "api/company/dictPc/getArea", // 获取开户行所在地区

  //提现管理
  getWithdrawManagerList: "api/company/withdraw/getWithdrawManagerList", // 获取提现管理记录  post/
  getWithdrawDetail: "api/company/withdraw/getWithdrawDetail", // 获取提现详情 get/
  authWithdraw: "api/company/withdraw/authWithdraw", // 提现申请审核 post/
  tradeWithdrawByTl: "api/company/withdraw/tradeWithdrawByTl", // 提现通联打款接口 get/
  closeWithdraw: "api/company/withdraw/closeWithdraw", // 提现撤销接口 get/
  doTradeByOffline: "api/company/withdraw/doTradeByOffline", //上传打款凭证,并完成打款 get/

  //商户信息
  getOtherCompanyInfoNoCache: "api/company/company/getOtherCompanyInfoNoCache", //获取其他公司信息 get/
  getCompanyChooseList: "api/company/company/getCompanyChooseList", //选择商户页面获取商户列表 get/
  //加盟商

  signAgency: "api/company/agency/signAgency", //签署代理合同 POST
  getAgencyList: "api/company/agency/getAgencyList", //获取代理商列表 POST
  confirmAgency: "api/company/agency/confirmAgency", //确认合同 POST
  changeAgency: "api/company/agency/changeAgency", //修改代理合同 POST
  getTopCompanyIdWithCompanyId: "api/company/agency/getTopCompanyIdWithCompanyId", //获取顶级服务商ID get
  getTopCompanyId: "api/company/agency/getTopCompanyId", //获取顶级服务商ID get
  getSigningAgencyDetail: "api/company/agency/getSigningAgencyDetail", //获取签署中代理合同详情 get
  getMyAgencyLevel: "api/company/agency/getMyAgencyLevel", //获取商户的代理层级 get
  getAgencyMaxRate: "api/company/agency/getCompanyMaxRate", //获取最大可分配收益比例 get
  getAgencyDetail: "api/company/agency/getAgencyDetail", //获取详情中-代理合同详情 get
  cancelSignAgency: "api/company/agency/cancelSignAgency", //取消签署代理合同或者取消合同变更 get
  getAgencyTimeLine: "api/company/agency/getAgencyTimeLine", //获取代理合同操作历史 get
  delAgency: "api/company/agency/delAgency", //解除加盟商关系 get

  // 站点管理
  authStation: "api/company/station/authStation", //站点审核
  editStation: "api/company/station/editStation", //修改站点申报信息
  getStationBuildStatus: "api/company/station/getStationBuildStatus", //获取站点建设进度
  getStationDeviceShipInfo: "api/company/station/getStationDeviceShipInfo", //获取设备出库详细信息
  getStationBuildInfo: "api/company/station/getStationBuildInfo", //获取站点施工详细信息
  applyStationDevice: "api/company/station/applyStationDevice", //申请站点设备
  deliveryStationDevice: "api/company/station/deliveryStationDevice", //设备出库
  cancelStationDeviceShip: "api/company/station/cancelStationDeviceShip", //取消设备出库
  confirmDeviceReceived: "api/company/station/confirmDeviceReceived", //确认收货
  submitBuildInfo: "api/company/station/submitBuildInfo", //提交施工信息
  authBuildInfo: "api/company/station/authBuildInfo", //验收站点
  getStationTimeLine: "api/company/station/getStationTimeLine", //获取站点操作历史

  //员工管理
  getCompanyUser: "api/company/rbac/getCompanyUser", //员工列表 post
  getCompanyUserDetail: "api/company/rbac/getCompanyUserDetail", //获取员工详情 get
  resetUserPassword: "api/company/rbac/resetUserPassword", //设置用户密码 get
  setCompanyUserValid: "api/company/rbac/setCompanyUserValid", //设置账户启用禁用 get
  delCompanyUser: "api/company/rbac/delCompanyUser", //删除员工 get
  getWebModuleRole: "api/company/rbac/getWebModuleRole", //获取web端权限模块列表 get
  getWxAppModuleRole: "api/company/rbac/getWxAppModuleRole", //获取小程序端权限模块列表 get
  saveRbacUser: "api/company/rbac/saveRbacUser", //保存员工信息 post
  getWebModuleUserRole: "api/company/rbac/getWebModuleUserRole", //获取用户的Web端模块权限列表 get
  getWxAppModuleUserRole: "api/company/rbac/getWxAppModuleUserRole", //获取用户的小程序端模块权限列表 get
  getDepList: "api/company/rbac/getDepList", //获取部门列表 get

  //菜单
  getUserMenu: "api/company/rbac/getUserMenu", //获取用户菜单 get

  // 套餐管理
  getAgentList: "api/company/meal/card/getCompanyList", // 获取商户列表
  getCouponList: "api/company/meal/card/listCardByPage", // 获取套餐列表
  getCouponTypeList: "api/company/meal/card/getCardType", // 获取套餐卡类型
  deleteCoupon: "api/company/meal/card/deleteById", // 删除套餐卡
  checkCouponPhone: "api/company/meal/card/checkPhone", // 平台端套餐售卖规则验证
  addSalesCoupon: "api/company/meal/card/web/addSell", // 新增售卖
  saveCoupon: "api/company/meal/card/add", // 保存套餐卡
  getCoupontDetail: "api/company/meal/card/findById", // 根据id获取套餐详情
  getCardSalesInfo: "api/company/meal/card/bListCardSellByPage", // 售卖详情分页

  getInsureOrderList: "api/order/secureService/getOrderList", // 获取保险订单列表

  servicePhoneUpdate: "api/company/customerService/phone/update", // 编辑客服电话
  servicePhoneAdd: "api/company/customerService/phone/add", // 新增客服电话
  servicePhoneQuery: "api/company/customerService/phone/query", //查询客服电话

  banWithEnableCoupon: "api/company/meal/card/enabledById", // 禁用/启用套餐卡
  delCouponCard: "api/company/meal/card/deleteSellById", // 删除套餐卡
};
