<template>
  <div>
    <img v-if="icon" :src="icon" class="a-wh-16" style="margin-right: 8px" />
    <span slot="title">{{ title }}</span>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "MenuItem",
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    colorClass: {
      type: String,
      default: "a-c-master",
    },
  },
};
</script>
